<template>
    <svg width="28" height="13" viewBox="0 0 28 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.85547 0L17.8733 6.52562L26.8913 0H8.85547Z" fill="#327eba" />
        <path d="M8.85547 0L17.8733 6.52562L26.8913 0H8.85547Z" fill="url(#icon-message-gradient-1)" />
        <path d="M18.3645 8.23926C18.2179 8.3453 18.0456 8.3984 17.8732 8.3984C17.7008 8.3984 17.5285 8.34536 17.3819 8.23926L7.74565 1.26611V2.13059H3.87283C3.40995 2.13059 3.03467 2.50586 3.03467 2.96875C3.03467 3.43164 3.40995 3.80692 3.87283 3.80692H7.74565V9.1063H3.87283C3.40995 9.1063 3.03467 9.48158 3.03467 9.94447C3.03467 10.4074 3.40995 10.7826 3.87283 10.7826H7.74565V12.0752C7.74565 12.5381 8.12093 12.9134 8.58382 12.9134H27.1626C27.6255 12.9134 28.0008 12.5381 28.0008 12.0752V1.26617L18.3645 8.23926Z" fill="#327eba" />
        <path d="M18.3645 8.23926C18.2179 8.3453 18.0456 8.3984 17.8732 8.3984C17.7008 8.3984 17.5285 8.34536 17.3819 8.23926L7.74565 1.26611V2.13059H3.87283C3.40995 2.13059 3.03467 2.50586 3.03467 2.96875C3.03467 3.43164 3.40995 3.80692 3.87283 3.80692H7.74565V9.1063H3.87283C3.40995 9.1063 3.03467 9.48158 3.03467 9.94447C3.03467 10.4074 3.40995 10.7826 3.87283 10.7826H7.74565V12.0752C7.74565 12.5381 8.12093 12.9134 8.58382 12.9134H27.1626C27.6255 12.9134 28.0008 12.5381 28.0008 12.0752V1.26617L18.3645 8.23926Z" fill="url(#icon-message-gradient-2)" />
        <path d="M5.54915 5.61865H0.838166C0.375277 5.61865 0 5.99393 0 6.45682C0 6.91971 0.375277 7.29499 0.838166 7.29499H5.54915C6.01204 7.29499 6.38731 6.91971 6.38731 6.45682C6.38731 5.99393 6.01204 5.61865 5.54915 5.61865Z" fill="#327eba" />
        <path d="M5.54915 5.61865H0.838166C0.375277 5.61865 0 5.99393 0 6.45682C0 6.91971 0.375277 7.29499 0.838166 7.29499H5.54915C6.01204 7.29499 6.38731 6.91971 6.38731 6.45682C6.38731 5.99393 6.01204 5.61865 5.54915 5.61865Z" fill="url(#icon-message-gradient-3)" />
        <defs>
            <linearGradient id="icon-message-gradient-1" x1="26.8913" y1="-0.966759" x2="10.2379" y2="8.85183" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-message-gradient-2" x1="28.0008" y1="-0.459408" x2="2.30719" y2="11.2892" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-message-gradient-3" x1="6.38731" y1="5.37031" x2="1.6014" y2="9.26039" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
        </defs>
    </svg>
</template>
