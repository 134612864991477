<template>
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.7578 1.48524L15.9706 0.698048C15.6463 0.373763 15.1169 0.373763 14.7898 0.698048L6.5353 8.95532L2.21125 4.63128C1.88696 4.30696 1.35752 4.30696 1.03045 4.63128L0.243214 5.41848C-0.0810714 5.7428 -0.0810714 6.2722 0.243214 6.59927L5.94213 12.2981C6.10568 12.4617 6.31631 12.5448 6.52975 12.5448C6.7432 12.5448 6.95662 12.4617 7.11742 12.2981L16.7495 2.66608C17.0821 2.33625 17.0821 1.8096 16.7578 1.48524Z" fill="url(#icon-check-gradient-1)"/>
        <defs>
            <linearGradient id="icon-check-gradient-1" x1="17" y1="0.454834" x2="-1.91954" y2="6.97456" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
        </defs>
    </svg>
</template>