import { createApp } from 'vue';
import axios from 'axios';

import App from './App.vue';
import store from './store';
import router from './router';

import '@/assets/css/global.css';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

const token = localStorage.getItem('token');
if (token !== undefined) { axios.defaults.headers.common['x-auth-token'] = token; }

createApp(App)
    .use(store)
    .use(router)
    .mount('#app');
