<template>
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.8239 10.7443H17.1226C17.296 11.2191 17.3907 11.7315 17.3907 12.2655V18.6958C17.3907 18.9184 17.352 19.1322 17.2815 19.3309H20.0942C21.1449 19.3309 21.9997 18.4761 21.9997 17.4255V13.92C21.9998 12.1689 20.5751 10.7443 18.8239 10.7443Z" fill="white" />
        <path d="M18.8239 10.7443H17.1226C17.296 11.2191 17.3907 11.7315 17.3907 12.2655V18.6958C17.3907 18.9184 17.352 19.1322 17.2815 19.3309H20.0942C21.1449 19.3309 21.9997 18.4761 21.9997 17.4255V13.92C21.9998 12.1689 20.5751 10.7443 18.8239 10.7443Z" fill="url(#icon-users-gradient-1)" />
        <path d="M4.60903 12.2655C4.60903 11.7314 4.70378 11.2191 4.8772 10.7443H3.17581C1.42467 10.7443 0 12.1689 0 13.9201V17.4255C0 18.4762 0.854775 19.331 1.90549 19.331H4.7183C4.64779 19.1321 4.60903 18.9184 4.60903 18.6958V12.2655Z" fill="white" />
        <path d="M4.60903 12.2655C4.60903 11.7314 4.70378 11.2191 4.8772 10.7443H3.17581C1.42467 10.7443 0 12.1689 0 13.9201V17.4255C0 18.4762 0.854775 19.331 1.90549 19.331H4.7183C4.64779 19.1321 4.60903 18.9184 4.60903 18.6958V12.2655Z" fill="url(#icon-users-gradient-2)" />
        <path d="M12.9448 9.08972H9.05521C7.30406 9.08972 5.87939 10.5144 5.87939 12.2655V18.6958C5.87939 19.0465 6.16376 19.331 6.51456 19.331H15.4855C15.8363 19.331 16.1206 19.0466 16.1206 18.6958V12.2655C16.1206 10.5144 14.696 9.08972 12.9448 9.08972Z" fill="white"/>
        <path d="M12.9448 9.08972H9.05521C7.30406 9.08972 5.87939 10.5144 5.87939 12.2655V18.6958C5.87939 19.0465 6.16376 19.331 6.51456 19.331H15.4855C15.8363 19.331 16.1206 19.0466 16.1206 18.6958V12.2655C16.1206 10.5144 14.696 9.08972 12.9448 9.08972Z" fill="url(#icon-users-gradient-3)" />
        <path d="M11 0.668945C8.894 0.668945 7.18066 2.38228 7.18066 4.4883C7.18066 5.91679 7.9691 7.16439 9.13346 7.8194C9.68574 8.13006 10.3224 8.30761 11 8.30761C11.6775 8.30761 12.3142 8.13006 12.8665 7.8194C14.0309 7.16439 14.8193 5.91675 14.8193 4.4883C14.8193 2.38232 13.106 0.668945 11 0.668945Z" fill="white" />
        <path d="M11 0.668945C8.894 0.668945 7.18066 2.38228 7.18066 4.4883C7.18066 5.91679 7.9691 7.16439 9.13346 7.8194C9.68574 8.13006 10.3224 8.30761 11 8.30761C11.6775 8.30761 12.3142 8.13006 12.8665 7.8194C14.0309 7.16439 14.8193 5.91675 14.8193 4.4883C14.8193 2.38232 13.106 0.668945 11 0.668945Z" fill="url(#icon-users-gradient-4)" />
        <path d="M4.29335 4.22888C2.71834 4.22888 1.43701 5.51021 1.43701 7.08522C1.43701 8.66024 2.71834 9.94156 4.29335 9.94156C4.69287 9.94156 5.07332 9.85881 5.41892 9.71005C6.01644 9.45279 6.50912 8.99741 6.81398 8.427C7.02796 8.02667 7.14969 7.56995 7.14969 7.08522C7.14969 5.51025 5.86837 4.22888 4.29335 4.22888Z" fill="white" />
        <path d="M4.29335 4.22888C2.71834 4.22888 1.43701 5.51021 1.43701 7.08522C1.43701 8.66024 2.71834 9.94156 4.29335 9.94156C4.69287 9.94156 5.07332 9.85881 5.41892 9.71005C6.01644 9.45279 6.50912 8.99741 6.81398 8.427C7.02796 8.02667 7.14969 7.56995 7.14969 7.08522C7.14969 5.51025 5.86837 4.22888 4.29335 4.22888Z" fill="url(#icon-users-gradient-5)" />
        <path d="M17.7064 4.22888C16.1314 4.22888 14.8501 5.51021 14.8501 7.08522C14.8501 7.56999 14.9718 8.02671 15.1858 8.427C15.4907 8.99746 15.9834 9.45284 16.5809 9.71005C16.9265 9.85881 17.3069 9.94156 17.7064 9.94156C19.2815 9.94156 20.5628 8.66024 20.5628 7.08522C20.5628 5.51021 19.2815 4.22888 17.7064 4.22888Z" fill="white" />
        <path d="M17.7064 4.22888C16.1314 4.22888 14.8501 5.51021 14.8501 7.08522C14.8501 7.56999 14.9718 8.02671 15.1858 8.427C15.4907 8.99746 15.9834 9.45284 16.5809 9.71005C16.9265 9.85881 17.3069 9.94156 17.7064 9.94156C19.2815 9.94156 20.5628 8.66024 20.5628 7.08522C20.5628 5.51021 19.2815 4.22888 17.7064 4.22888Z" fill="url(#icon-users-gradient-6)" />
        <defs>
            <linearGradient id="icon-users-gradient-1" x1="21.9997" y1="10.7443" x2="16.0427" y2="11.5735" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-users-gradient-2" x1="4.8772" y1="10.7443" x2="-1.07985" y2="11.5735" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-users-gradient-3" x1="16.1206" y1="9.08972" x2="4.09199" y2="12.0376" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-users-gradient-4" x1="14.8193" y1="0.668945" x2="5.84749" y2="2.86769" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-users-gradient-5" x1="7.14969" y1="4.22888" x2="0.439976" y2="5.87326" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-users-gradient-6" x1="20.5628" y1="4.22888" x2="13.8531" y2="5.87326" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
        </defs>
    </svg>
</template>
