import { createRouter, createWebHistory } from 'vue-router';

// Load vuex store
import store from '../store';

// Load route views
import HomeView from '@/views/HomeView.vue';
import CrashView from '@/views/CrashView.vue';
import RollView from '@/views/RollView.vue';
import BlackjackView from '@/views/blackjack/BlackjackView.vue';
import BlackjackOverviewView from '@/views/blackjack/BlackjackOverviewView.vue';
import BlackjackTablesView from '@/views/blackjack/BlackjackTablesView.vue';
import BlackjackTableView from '@/views/blackjack/BlackjackTableView.vue';
import DuelsView from '@/views/DuelsView.vue';
import MinesView from '@/views/MinesView.vue';
import TowersView from '@/views/TowersView.vue';
import UnboxView from '@/views/unbox/UnboxView.vue';
import UnboxOverviewView from '@/views/unbox/UnboxOverviewView.vue';
import UnboxBoxView from '@/views/unbox/UnboxBoxView.vue';
import BattlesView from '@/views/battles/BattlesView.vue';
import BattlesOverviewView from '@/views/battles/BattlesOverviewView.vue';
import BattlesGameView from '@/views/battles/BattlesGameView.vue';
import BattlesCreateView from '@/views/battles/BattlesCreateView.vue';
import UpgraderView from '@/views/UpgraderView.vue';
import LimitedsView from '@/views/limiteds/LimitedsView.vue';
import LimitedsDepositView from '@/views/limiteds/LimitedsDepositView.vue';
import LimitedsWithdrawView from '@/views/limiteds/LimitedsWithdrawView.vue';
import LeaderboardView from '@/views/LeaderboardView.vue';

const ProfileView = () => import(/* webpackChunkName: "group-user" */ '@/views/profile/ProfileView.vue');
const ProfileTransactionsView = () => import(/* webpackChunkName: "group-user" */ '@/views/profile/ProfileTransactionsView.vue');
const ProfileGamesView = () => import(/* webpackChunkName: "group-user" */ '@/views/profile/ProfileGamesView.vue');
const ProfileSettingsView = () => import(/* webpackChunkName: "group-user" */ '@/views/profile/ProfileSettingsView.vue');
const RewardsView = () => import(/* webpackChunkName: "group-user" */ '@/views/RewardsView.vue');
const AffiliatesView = () => import(/* webpackChunkName: "group-user" */ '@/views/AffiliatesView.vue');

const AdminView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminView.vue');
const AdminDashboardView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminDashboardView.vue');
const AdminUsersView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminUsersView.vue');
const AdminAffiliatesView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminAffiliatesView.vue');
const AdminPromoView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminPromoView.vue');
const AdminCashierView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminCashierView.vue');
const AdminBoxesView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminBoxesView.vue');
const AdminRainView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminRainView.vue');
const AdminLeaderboardsView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminLeaderboardsView.vue');
const AdminFilterView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminFilterView.vue');
const AdminStatsView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminStatsView.vue');
const AdminSettingsView = () => import(/* webpackChunkName: "group-admin" */ '@/views/admin/AdminSettingsView.vue');

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'Home',
            component: HomeView
        },
        {
            path: '/crash',
            name: 'Crash',
            component: CrashView
        },
        {
            path: '/roll',
            name: 'Roll',
            component: RollView
        },
        {
            path: '/blackjack',
            component: BlackjackView,
            children: [
                {
                    path: '',
                    name: 'BlackjackOverview',
                    component: BlackjackOverviewView
                },
                {
                    path: 'tables',
                    name: 'BlackjackTables',
                    component: BlackjackTablesView
                },
                {
                    path: 'table/:tableId',
                    name: 'BlackjackTable',
                    component: BlackjackTableView
                }
            ]
        },
        {
            path: '/duels',
            name: 'Duels',
            component: DuelsView
        },
        {
            path: '/mines',
            name: 'Mines',
            component: MinesView
        },
        {
            path: '/towers',
            name: 'Towers',
            component: TowersView
        },
        {
            path: '/unbox',
            component: UnboxView,
            children: [
                {
                    path: '',
                    name: 'UnboxOverview',
                    component: UnboxOverviewView
                },
                {
                    path: ':boxId',
                    name: 'UnboxBox',
                    component: UnboxBoxView
                }
            ]
        },
        {
            path: '/battles',
            component: BattlesView,
            children: [
                {
                    path: '',
                    name: 'BattlesOverview',
                    component: BattlesOverviewView
                },
                {
                    path: 'create',
                    name: 'BattlesCreate',
                    meta: {
                        auth: true
                    },
                    component: BattlesCreateView
                },
                {
                    path: ':gameId',
                    name: 'BattlesGame',
                    component: BattlesGameView
                }
            ]
        },
        {
            path: '/upgrader',
            name: 'Upgrader',
            component: UpgraderView
        },
        {
            path: '/limiteds',
            component: LimitedsView,
            meta: {
                auth: true
            },
            children: [
                {
                    path: 'deposit',
                    name: 'LimitedsDeposit',
                    component: LimitedsDepositView
                },
                {
                    path: 'withdraw',
                    name: 'LimitedsWithdraw',
                    component: LimitedsWithdrawView
                }
            ]
        },
        {
            path: '/profile',
            component: ProfileView,
            meta: {
                auth: true
            },
            children: [
                {
                    path: '',
                    name: 'ProfileTransactions',
                    component: ProfileTransactionsView
                },
                {
                    path: 'games',
                    name: 'ProfileGames',
                    component: ProfileGamesView
                },
                {
                    path: 'settings',
                    name: 'ProfileSettings',
                    component: ProfileSettingsView
                }
            ]
        },
        {
            path: '/rewards',
            name: 'Rewards',
            meta: {
                auth: true
            },
            component: RewardsView
        },
        {
            path: '/affiliates',
            name: 'Affiliates',
            meta: {
                auth: true
            },
            component: AffiliatesView
        },
        {
            path: '/leaderboard',
            name: 'Leaderboard',
            component: LeaderboardView
        },
        {
            path: '/admin',
            component: AdminView,
            meta: {
                auth: true,
                admin: true
            },
            children: [
                {
                    path: '',
                    name: 'AdminDashboard',
                    component: AdminDashboardView
                },
                {
                    path: 'users',
                    name: 'AdminUsers',
                    component: AdminUsersView
                },
                {
                    path: 'affiliates',
                    name: 'AdminAffiliates',
                    component: AdminAffiliatesView
                },
                {
                    path: 'promo',
                    name: 'AdminPromo',
                    component: AdminPromoView
                },
                {
                    path: 'cashier',
                    name: 'AdminCashier',
                    component: AdminCashierView
                },
                {
                    path: 'boxes',
                    name: 'AdminBoxes',
                    component: AdminBoxesView
                },
                {
                    path: 'rain',
                    name: 'AdminRain',
                    component: AdminRainView
                },
                {
                    path: 'leaderboard',
                    name: 'AdminLeaderboard',
                    component: AdminLeaderboardsView
                },
                {
                    path: 'filter',
                    name: 'AdminFilter',
                    component: AdminFilterView
                },
                {
                    path: 'stats',
                    name: 'AdminStats',
                    component: AdminStatsView
                }, 
                {
                    path: 'settings',
                    name: 'AdminSettings',
                    component: AdminSettingsView
                }
            ]
        },
        {
            path: '/verify',
            beforeEnter: (to, from, next) => {
                const data = { userId: to.query.userId, token: to.query.token };
                store.dispatch('authSendCredentialsVerify', data);
    
                next('/');
            }
        },
        {
            path: '/reset',
            beforeEnter: (to, from, next) => {
                store.dispatch('modalsSetData', { userId: to.query.userId, token: to.query.token });
                store.dispatch('modalsSetShow', 'Reset');
    
                next('/');
            }
        }
    ]
});

router.beforeEach(async function(to, from, next) {
    if(store.getters.authToken !== null && store.getters.authUser.user === null && store.getters.authUser.loading === false) {
        await store.dispatch('authGetUser');
    }

    const affiliateCode = to.query.a !== undefined ? to.query.a : localStorage.getItem('affiliate-code') !== null ? localStorage.getItem('affiliate-code') : null;
    if(affiliateCode !== null) {
        if(store.getters.authUser.user !== null) {
            localStorage.removeItem('affiliate-code');
            store.dispatch('modalsSetData', { code: affiliateCode });
            store.dispatch('modalsSetShow', 'Claim');
        } else { localStorage.setItem('affiliate-code', affiliateCode); }
    }

    if(to.matched.some(record => record.meta.auth) && store.getters.authUser.user === null) {
        next(false);
    } else if(to.matched.some(record => record.meta.admin) && (store.getters.authUser.user === null || store.getters.authUser.user.rank !== 'admin')) {
        next(false);
    } else {
        next();
    }
});

export default router;
