<template>
    <svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5176 0H0.482354C0.0547936 0 -0.16302 0.516304 0.143533 0.822859L4.66116 5.34052C4.8467 5.52607 5.15325 5.52607 5.33888 5.34052L9.8565 0.822859C10.163 0.516304 9.94516 0 9.5176 0Z" />
        <defs>
            <linearGradient id="icon-dropdown-gradient" x1="7" y1="0" x2="-0.361467" y2="3.15718" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
        </defs>
    </svg>
</template>
