const state = {
    affiliatesData: {
        data: null,
        loading: false
    },
    affiliatesUserList: {
        data: null,
        count: null,
        loading: false,
        page: 1
    }
}

const getters = {
    affiliatesData: state => state.affiliatesData,
    affiliatesUserList: state => state.affiliatesUserList
}

const mutations = {
    affiliates_set_data(state, data) {
        state.affiliatesData.data = data.data;
    },
    affiliates_set_data_loading(state, status) {
        state.affiliatesData.loading = status;
    },
    affiliates_set_user_list(state, data) {
        state.affiliatesUserList.data = data.users;
        state.affiliatesUserList.count = data.count;
    },
    affiliates_set_user_list_loading(state, status) {
        state.affiliatesUserList.loading = status;
    },
    affiliates_set_user_list_page(state, page) {
        state.affiliatesUserList.page = page;
    }
}

const actions = {
    affiliatesSetUserListPage({ commit }, page) {
        commit('affiliates_set_user_list_page', page);
    },
    affiliatesGetDataSocket({ getters, commit, dispatch }, data) {
        if(getters.socketGeneral === null || getters.affiliatesData.loading === true) { return; }
        commit('affiliates_set_data_loading', true);

        getters.socketGeneral.emit('getAffiliateData', data, (res) => {
            if(res.success === true) {
                commit('affiliates_set_data', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('affiliates_set_data_loading', false);
        });
    },
    affiliatesGetUserListSocket({ getters, commit, dispatch }, data) {
        if(getters.socketGeneral === null || getters.affiliatesUserList.loading === true) { return; }
        commit('affiliates_set_user_list_loading', true);

        getters.socketGeneral.emit('getAffiliateUserList', data, (res) => {
            if(res.success === true) {
                commit('affiliates_set_user_list', res);
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('affiliates_set_user_list_loading', false);
        });
    },
    affiliatesSendCodeSocket({ getters, commit, dispatch }, data) {
        if(getters.socketGeneral === null || getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesCode');

        getters.socketGeneral.emit('sendAffiliateCode', data, (res) => {
            if(res.success === true) {
                commit('affiliates_set_data_data', res.data);
                
                dispatch('notificationShow', { type: 'success', message: 'You have successfully updated your affiliate code.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    affiliatesSendClaimCodeSocket({ getters, commit, dispatch }, data) {
        if(getters.socketGeneral === null || getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesClaimCode');

        getters.socketGeneral.emit('sendAffiliateClaimCode', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);

                dispatch('notificationShow', { type: 'success', message: 'You have successfully claimed a affiliate code.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
    affiliatesSendClaimEarningsSocket({ getters, commit, dispatch }, data) {
        if(getters.socketGeneral === null || getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'AffiliatesClaimEarnings');

        getters.socketGeneral.emit('sendAffiliateClaimEarnings', data, (res) => {
            if(res.success === true) {
                commit('auth_update_user', res.user);
                commit('affiliates_set_data_data', res.user.affiliates);

                dispatch('notificationShow', { type: 'success', message: 'You have successfully claimed your affiliate earnings.' });
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    }
}

const affiliates = {
    state,
    mutations,
    actions,
    getters
}

export default affiliates;
