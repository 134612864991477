<template>
    <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.02271 0.0527344C4.43183 0.0527344 5.6629 0.829923 6.30984 1.97805C6.95671 0.829923 8.18778 0.0527344 9.59697 0.0527344H12.6197V3.71943C12.6197 5.79818 10.9285 7.48937 8.84971 7.48937H8.72434V9.19881C8.72434 10.5817 9.84934 11.7067 11.2322 11.7067H27.8971C30.1594 11.7067 32 13.5473 32 15.8096V20.2236C32 22.4859 30.1594 24.3265 27.8971 24.3265H18.3878C18.1468 26.4965 16.3016 28.1897 14.0683 28.1897H13.5855V24.3265H12.6811C7.83659 24.3265 3.89533 20.3851 3.89533 15.5406V7.48937H3.76996C1.69114 7.48937 -4.95911e-05 5.79818 -4.95911e-05 3.71943V0.0527344H3.02271ZM18.8125 20.7462H20.6875V18.8712H18.8125V20.7462Z" fill="white"/>
        <path d="M3.02271 0.0527344C4.43183 0.0527344 5.6629 0.829923 6.30984 1.97805C6.95671 0.829923 8.18778 0.0527344 9.59697 0.0527344H12.6197V3.71943C12.6197 5.79818 10.9285 7.48937 8.84971 7.48937H8.72434V9.19881C8.72434 10.5817 9.84934 11.7067 11.2322 11.7067H27.8971C30.1594 11.7067 32 13.5473 32 15.8096V20.2236C32 22.4859 30.1594 24.3265 27.8971 24.3265H18.3878C18.1468 26.4965 16.3016 28.1897 14.0683 28.1897H13.5855V24.3265H12.6811C7.83659 24.3265 3.89533 20.3851 3.89533 15.5406V7.48937H3.76996C1.69114 7.48937 -4.95911e-05 5.79818 -4.95911e-05 3.71943V0.0527344H3.02271ZM18.8125 20.7462H20.6875V18.8712H18.8125V20.7462Z" fill="url(#icon-whale-gradient)"/>
        <defs>
            <linearGradient id="icon-whale-gradient" x1="-5.00679e-05" y1="0.0527361" x2="36.9703" y2="10.3572" gradientUnits="userSpaceOnUse">
                <stop stop-color="#fca311"/>
                <stop offset="1" stop-color="#ffb703"/>
            </linearGradient>
        </defs>
    </svg>
</template>
