<template>
    <div class="app-maintenance">
        <img src="@/assets/img/logo.png" />
        <div class="maintenance-text">
            <span class="gradient-green">UNDER MAINTENANCE</span>
            Oops! We’re currently under maintenance and are working towards getting you back as soon as possible. Follow our socials to stay updated!
        </div>
        <div class="maintenance-socials">
            <a href="https://twitter.com/RBLXRoll" target="_blank">
                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 2.12903C21.175 2.48848 20.3225 2.73733 19.415 2.84793C20.35 2.29493 21.065 1.41014 21.395 0.331797C20.515 0.857143 19.5525 1.21659 18.535 1.43779C17.71 0.552996 16.5275 0 15.235 0C12.7325 0 10.725 2.04608 10.725 4.53456C10.725 4.89401 10.7525 5.22581 10.835 5.5576C7.095 5.3917 3.795 3.56682 1.5675 0.829493C-0.0274998 3.70507 1.76 6.08295 2.9425 6.88479C2.2275 6.88479 1.5125 6.66359 0.9075 6.3318C0.9075 8.57143 2.475 10.424 4.51 10.8387C4.07 10.977 3.08 11.0599 2.475 10.9217C3.0525 12.7189 4.73 14.0461 6.6825 14.0737C5.1425 15.2903 2.8875 16.2581 0 15.9539C2.0075 17.2535 4.3725 18 6.93 18C15.235 18 19.745 11.0876 19.745 5.11521C19.745 4.92166 19.745 4.72811 19.7175 4.53456C20.6525 3.84332 21.4225 3.04147 22 2.12903Z" />
                </svg>
            </a>
            <a href="https://discord.com/invite/rblxroll" target="_blank">
                <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.28263 13.9361C10.4971 15.9711 14.9346 16.3675 19.9632 13.2C19.9264 13.2553 18.9316 14.7289 16.3158 15.4842C16.8685 16.2395 17.6237 17.1052 17.6237 17.1052C19.3001 17.1052 20.9396 16.6263 22.3579 15.7052C23.4632 14.9684 24.0895 13.6973 23.9605 12.371C23.7395 10.1052 23.2053 7.8947 22.3764 5.77632C21.3264 3.01318 18.8027 1.09737 15.8737 0.821036C15.6158 0.802607 15.4316 0.802607 15.3211 0.802607L15.0264 1.09732C18.3975 2.05522 20.0738 3.54732 20.1106 3.60261C14.9343 0.986794 8.81851 0.949989 3.60531 3.49208C3.60531 3.49208 5.26322 1.85261 8.92898 0.986794L8.70793 0.765747C8.32107 0.765747 7.95264 0.802606 7.56584 0.857841C4.85793 1.31836 2.59217 3.17889 1.61584 5.73941C0.768452 7.94993 0.215835 10.271 0.0132177 12.6289C-0.0973057 13.8815 0.492171 15.1157 1.52374 15.8342C2.88688 16.7368 4.50793 17.2157 6.14741 17.2157C6.14741 17.2157 6.81055 16.3499 7.47374 15.5763C4.98688 14.8394 3.97374 13.3657 3.95531 13.3105L4.42333 13.5494C4.70311 13.6924 4.98994 13.8219 5.28263 13.9361ZM8.17374 12.3342C6.97636 12.2973 6.03688 11.2842 6.07374 10.0684C6.1106 8.92627 7.03165 8.00522 8.17374 7.96836C9.37112 8.00522 10.3106 9.01836 10.2737 10.2342C10.2185 11.3763 9.31583 12.2974 8.17374 12.3342ZM15.6895 12.3342C14.4921 12.2973 13.5526 11.2842 13.5895 10.0684C13.6264 8.92627 14.5474 8.00522 15.6895 7.96836C16.8869 8.00522 17.8264 9.01836 17.7895 10.2342C17.7527 11.3763 16.8316 12.2974 15.6895 12.3342Z" />
                </svg>
            </a>
            <a href="https://www.youtube.com/channel/UC53flZWOjRMfPfV88v_lqmQ/featured" target="_blank">
                <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.5055 2.62938C23.2288 1.60125 22.4182 0.790829 21.3903 0.513975C19.5122 0 11.9998 0 11.9998 0C11.9998 0 4.48753 0 2.60943 0.494383C1.60125 0.771054 0.770871 1.60143 0.4942 2.62938C0 4.50731 0 8.40194 0 8.40194C0 8.40194 0 12.3162 0.4942 14.1745C0.771054 15.2025 1.58148 16.0131 2.60961 16.2899C4.50731 16.8039 12 16.8039 12 16.8039C12 16.8039 19.5122 16.8039 21.3903 16.3095C22.4184 16.0328 23.2288 15.2222 23.5057 14.1943C23.9999 12.3162 23.9999 8.42172 23.9999 8.42172C23.9999 8.42172 24.0197 4.50731 23.5055 2.62938Z" />
                    <path d="M9.60791 12L15.8549 8.40198L9.60791 4.80396V12Z" fill="#133552"/>
                </svg>
            </a>
            <a href="https://www.twitch.tv/rblxroll" target="_blank">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.74106 0L0.436768 3.47813V17.3898H5.21724V20H7.827L10.4344 17.3898H14.3461L19.5633 12.1746V0H1.74106ZM3.47895 1.73789H17.825V11.3035L14.7813 14.3473H10L7.39341 16.9539V14.3473H3.47895V1.73789ZM8.26098 10.4352H10V5.21836H8.26098V10.4352ZM13.0426 10.4352H14.7813V5.21836H13.0426V10.4352Z" />
                </svg>
            </a>
        </div>
        <div class="maintenance-bottom">
            <img src="@/assets/img/maintenance-1.png" />
            <img src="@/assets/img/maintenance-2.png" />
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
    .app-maintenance {
        width: 100%;
        padding: 75px 250px 25px 175px;
        font-family: 'Rubik';
    }

    .app-maintenance img {
        width: 200px;
    }

    .app-maintenance .maintenance-text {
        width: 750px;
        display: flex;
        flex-direction: column;
        margin-top: 90px;
        font-size: 18px;
        font-weight: 300;
        color: #bbbfd0;
    }

    .app-maintenance .maintenance-text span {
        margin-bottom: 25px;
        font-size: 64px;
        font-weight: 900;
    }

    .app-maintenance .maintenance-socials {
        width: 750px;
        display: flex;
        align-items: center;
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid rgba(24, 72, 109, 0.5);
    }

    .app-maintenance .maintenance-socials a {
        width: 52px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        background-color: #133552;
        clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
    }

    .app-maintenance .maintenance-socials a:last-of-type {
        margin-right: 0;
    }

    .app-maintenance .maintenance-socials a svg {
        fill: #2e71a8;
        transition: fill 0.3s ease;
    }

    .app-maintenance .maintenance-socials a:hover svg {
        fill: #ffffff;
    }

    .app-maintenance .maintenance-bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
    }

    .app-maintenance .maintenance-bottom img:nth-child(1) {
        width: 550px;
    }

    .app-maintenance .maintenance-bottom img:nth-child(2) {
        width: 390px;
    }

    @media only screen and (max-width: 1600px) {

        .app-maintenance {
            width: 100%;
            padding: 75px 100px 25px 100px;
            font-family: 'Rubik';
        }

    }

    @media only screen and (max-width: 1400px) {

        .app-maintenance .maintenance-bottom img:nth-child(2) {
            display: none;
        }

    }

    @media only screen and (max-width: 950px) {

        .app-maintenance {
            padding: 75px 50px 25px 50px;
        }

        .app-maintenance .maintenance-text {
            width: 100%;
        }

        .app-maintenance .maintenance-text span {
            font-size: 50px;
        }

        .app-maintenance .maintenance-socials {
            width: 100%;
        }

        .app-maintenance .maintenance-bottom img:nth-child(1) {
            width: 400px;
        }

    }

    @media only screen and (max-width: 700px) {

        .app-maintenance {
            padding: 50px 50px 25px 50px;
        }

        .app-maintenance .maintenance-text {
            font-size: 16px;
        }

        .app-maintenance .maintenance-text span {
            font-size: 38px;
        }

    }

    @media only screen and (max-width: 550px) {

        .app-maintenance {
            padding: 50px 25px 25px 25px;
        }

        .app-maintenance img {
            width: 180px;
        }

        .app-maintenance .maintenance-text {
            font-size: 15px;
        }

        .app-maintenance .maintenance-text span {
            font-size: 34px;
        }

        .app-maintenance .maintenance-bottom img:nth-child(1) {
            width: 100%;
        }

    }

    @media only screen and (max-width: 450px) {

        .app-maintenance img {
            width: 150px;
        }

        .app-maintenance .maintenance-text {
            font-size: 13px;
        }

        .app-maintenance .maintenance-text span {
            margin-bottom: 15px;
            font-size: 28px;
        }

    }
</style>
