<template>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4587 5.46502C16.6427 3.73749 14.7403 2.77634 12.8504 3.16665C10.4982 -0.573298 4.71754 0.112158 3.29423 4.28153C-1.25292 4.81452 -1.03415 11.2188 3.58851 11.4037H17.262C18.856 11.4383 20.3082 10.0301 20.274 8.43117C20.2741 6.85281 19.0103 5.56501 17.4587 5.46502Z" fill="white"/>
        <path d="M17.4587 5.46502C16.6427 3.73749 14.7403 2.77634 12.8504 3.16665C10.4982 -0.573298 4.71754 0.112158 3.29423 4.28153C-1.25292 4.81452 -1.03415 11.2188 3.58851 11.4037H17.262C18.856 11.4383 20.3082 10.0301 20.274 8.43117C20.2741 6.85281 19.0103 5.56501 17.4587 5.46502Z" fill="url(#icon-rain-gradient-1)"/>
        <path d="M1.84473 16.774C1.90743 18.3512 4.16034 18.3507 4.22272 16.774C4.22272 16.1173 3.03372 14.396 3.03372 14.396C3.03372 14.396 1.84473 16.1173 1.84473 16.774Z" fill="white"/>
        <path d="M1.84473 16.774C1.90743 18.3512 4.16034 18.3507 4.22272 16.774C4.22272 16.1173 3.03372 14.396 3.03372 14.396C3.03372 14.396 1.84473 16.1173 1.84473 16.774Z" fill="url(#icon-rain-gradient-2)"/>
        <path d="M5.41211 19.7464C5.47481 21.3236 7.72772 21.323 7.7901 19.7464C7.7901 19.0898 6.60111 17.3684 6.60111 17.3684C6.60111 17.3684 5.41211 19.0897 5.41211 19.7464Z" fill="white"/>
        <path d="M5.41211 19.7464C5.47481 21.3236 7.72772 21.323 7.7901 19.7464C7.7901 19.0898 6.60111 17.3684 6.60111 17.3684C6.60111 17.3684 5.41211 19.0897 5.41211 19.7464Z" fill="url(#icon-rain-gradient-3)"/>
        <path d="M8.97852 16.774C9.04122 18.3512 11.2941 18.3507 11.3565 16.774C11.3565 16.1173 10.1675 14.396 10.1675 14.396C10.1675 14.396 8.97852 16.1173 8.97852 16.774Z" fill="white"/>
        <path d="M8.97852 16.774C9.04122 18.3512 11.2941 18.3507 11.3565 16.774C11.3565 16.1173 10.1675 14.396 10.1675 14.396C10.1675 14.396 8.97852 16.1173 8.97852 16.774Z" fill="url(#icon-rain-gradient-4)"/>
        <path d="M12.5459 19.7464C12.6086 21.3236 14.8615 21.323 14.9239 19.7464C14.9239 19.0898 13.7349 17.3684 13.7349 17.3684C13.7349 17.3684 12.5459 19.0897 12.5459 19.7464Z" fill="white"/>
        <path d="M12.5459 19.7464C12.6086 21.3236 14.8615 21.323 14.9239 19.7464C14.9239 19.0898 13.7349 17.3684 13.7349 17.3684C13.7349 17.3684 12.5459 19.0897 12.5459 19.7464Z" fill="url(#icon-rain-gradient-5)"/>
        <path d="M16.1123 16.774C16.175 18.3512 18.4279 18.3507 18.4903 16.774C18.4903 16.1173 17.3013 14.396 17.3013 14.396C17.3013 14.396 16.1123 16.1173 16.1123 16.774Z" fill="white"/>
        <path d="M16.1123 16.774C16.175 18.3512 18.4279 18.3507 18.4903 16.774C18.4903 16.1173 17.3013 14.396 17.3013 14.396C17.3013 14.396 16.1123 16.1173 16.1123 16.774Z" fill="url(#icon-rain-gradient-6)"/>
        <defs>
            <linearGradient id="icon-rain-gradient-1" x1="20.2746" y1="0.719849" x2="-0.480162" y2="10.3718" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-rain-gradient-2" x1="4.22272" y1="14.396" x2="1.33919" y2="14.8679" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-rain-gradient-3" x1="7.7901" y1="17.3684" x2="4.90657" y2="17.8404" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-rain-gradient-4" x1="11.3565" y1="14.396" x2="8.47298" y2="14.8679" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-rain-gradient-5" x1="14.9239" y1="17.3684" x2="12.0404" y2="17.8404" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-rain-gradient-6" x1="18.4903" y1="14.396" x2="15.6068" y2="14.8679" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
        </defs>
    </svg>
</template>
