<template>
    <nav id="navbar">
        <div class="navbar-left">
            <button v-on:click="generalSetSidebarMobile(generalSidebarMobile === 'Chat' ? null : 'Chat')" class="button-chat">
                <div class="button-inner">
                    <IconChatGradient />
                </div>
            </button>
            <RouterLink to="/" class="link-logo">
                <img src="@/assets/img/logo.png" />
            </RouterLink>
            <RouterLink to="/" class="link-home">
                <div class="link-inner">
                    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 3.78027L3.15384 10.2486C3.15384 10.2578 3.15155 10.2712 3.14696 10.2895C3.14247 10.3077 3.14008 10.3209 3.14008 10.3302V16.8801C3.14008 17.1166 3.22654 17.3216 3.39941 17.4942C3.57224 17.6668 3.7769 17.7537 4.01344 17.7537H9.25322V12.5137H12.7469V17.7539H17.9866C18.2231 17.7539 18.4281 17.6672 18.6007 17.4942C18.7735 17.3218 18.8603 17.1167 18.8603 16.8801V10.3302C18.8603 10.2939 18.8553 10.2665 18.8465 10.2486L11 3.78027Z" />
                        <path d="M21.8482 8.77487L18.86 6.29133V0.723807C18.86 0.596528 18.8191 0.491857 18.737 0.409936C18.6555 0.32811 18.5508 0.287198 18.4233 0.287198H15.8033C15.6759 0.287198 15.5713 0.32811 15.4893 0.409936C15.4075 0.491857 15.3667 0.596576 15.3667 0.723807V3.38471L12.0372 0.600878C11.7464 0.364339 11.4006 0.246094 11.0003 0.246094C10.6 0.246094 10.2543 0.364339 9.96325 0.600878L0.151633 8.77487C0.0606786 8.84751 0.0108283 8.9453 0.00155609 9.06814C-0.00766837 9.19088 0.0241154 9.29808 0.0970029 9.38899L0.943024 10.3988C1.01591 10.4806 1.11131 10.5307 1.2296 10.549C1.33882 10.5582 1.44803 10.5262 1.55724 10.4535L11 2.5796L20.4429 10.4534C20.5158 10.5169 20.6112 10.5486 20.7295 10.5486H20.7705C20.8886 10.5306 20.9838 10.4802 21.0571 10.3986L21.9032 9.38894C21.9759 9.29784 22.0078 9.19083 21.9984 9.06795C21.989 8.94545 21.939 8.84766 21.8482 8.77487Z" />
                    </svg>
                </div>
            </RouterLink>
            <NavbarHomeDropdown />
        </div>

        <div class="navbar-mid">
            <AuthButton v-if="authUser.user === null" />
            <NavbarCashier v-else />
        </div>

        <div class="navbar-right">
            <AuthButton v-if="authUser.user === null" />
            <div v-else class="right-auth">
                <NavbarUserDropdown />
            </div>
            <button v-on:click="generalSetSidebarMobile(generalSidebarMobile === 'Navbar' ? null : 'Navbar')" class="button-toggle">
                <div class="button-inner">
                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="-0.000976562" y="0.889648" width="12.0008" height="1.62403" />
                        <rect x="-0.000976562" y="4.1377" width="12.0008" height="1.62403" />
                        <rect x="-0.000976562" y="7.38574" width="12.0008" height="1.62403" />
                    </svg>
                </div>
            </button>
        </div>

        <div class="navbar-mobile" v-bind:class="{ 'mobile-open': generalSidebarMobile === 'Navbar' }">
            <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/">
                <IconHome />
                <span class="text-green-gradient-exact">Home</span>
            </RouterLink>
            <div v-if="authUser.user !== null" class="mobile-user">
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/profile">
                    <IconUser />
                    Profile
                </RouterLink>
                <button v-on:click="navbarVaultButton()">
                    <IconVault />
                    Vault
                </button>
                <RouterLink  v-on:click="generalSetSidebarMobile(null)" to="/rewards">
                    <IconRakeback />
                    Rewards
                </RouterLink>
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/affiliates">
                    <IconAffiliates />
                    <span class="text-green-gradient-exact">Affiliates</span>
                </RouterLink>
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/leaderboard">
                    <IconLeaderboard />
                    <span class="text-green-gradient-exact">Leaderboard</span>
                </RouterLink>
                <RouterLink v-if="authUser.user.rank === 'admin'" v-on:click="generalSetSidebarMobile(null)" to="/admin">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
                    </svg>
                    Admin
                </RouterLink>
                <button v-on:click="authLogoutUser">
                    <IconSignOut />
                    Sign Out
                </button>
            </div>
            <div class="mobile-games">
                ALL GAMES
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/battles">
                    <IconBattles />
                    <span class="text-green-gradient-exact">Battles</span>
                </RouterLink>
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/unbox">
                    <IconUnbox />
                    <span class="text-green-gradient-exact">Unbox</span>
                </RouterLink>
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/upgrader">
                    <IconUpgrader />
                    <span class="text-green-gradient-exact">Upgrader</span>
                </RouterLink>
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/crash">
                    <IconCrash />
                    <span class="text-green-gradient-exact">Crash</span>
                </RouterLink>
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/roll" class="link-blackjack">
                    <IconRoll />
                    <span class="text-green-gradient-exact">Roll</span>
                </RouterLink>
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/blackjack" class="link-blackjack">
                    <IconBlackjack />
                    <span class="text-green-gradient-active">Blackjack</span>
                </RouterLink>
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/duels">
                    <IconDuels />
                    <span class="text-green-gradient-exact">Dice Duels</span>
                </RouterLink>
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/mines">
                    <IconMines />
                    <span class="text-green-gradient-exact">Mines</span>
                </RouterLink>
                <RouterLink v-on:click="generalSetSidebarMobile(null)" to="/towers">
                    <IconTowers />
                    <span class="text-green-gradient-exact">Towers</span>
                </RouterLink>
            </div>
        </div>
    </nav>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconChatGradient from '@/components/icons/IconChatGradient.vue';
    import IconHome from '@/components/icons/IconHome.vue';
    import IconUser from '@/components/icons/IconUser.vue';
    import IconVault from '@/components/icons/IconVault.vue';
    import IconRakeback from '@/components/icons/IconRakeback.vue';
    import IconAffiliates from '@/components/icons/IconAffiliates.vue';
    import IconLeaderboard from '@/components/icons/IconLeaderboard.vue';
    import IconSignOut from '@/components/icons/IconSignOut.vue';
    import IconBattles from '@/components/icons/IconBattles.vue';
    import IconUnbox from '@/components/icons/IconUnbox.vue';
    import IconUpgrader from '@/components/icons/IconUpgrader.vue';
    import IconCrash from '@/components/icons/IconCrash.vue';
    import IconBlackjack from '@/components/icons/IconBlackjack.vue';
    import IconRoll from '@/components/icons/IconRoll.vue';
    import IconDuels from '@/components/icons/IconDuels.vue';
    import IconMines from '@/components/icons/IconMines.vue';
    import IconTowers from '@/components/icons/IconTowers.vue';
    import AuthButton from '@/components/AuthButton.vue';
    import NavbarHomeDropdown from '@/components/navbar/NavbarHomeDropdown.vue';
    import NavbarCashier from '@/components/navbar/NavbarCashier.vue';
    import NavbarUserDropdown from '@/components/navbar/NavbarUserDropdown.vue';

    export default {
        components: {
            IconChatGradient,
            IconHome,
            IconUser,
            IconVault,
            IconRakeback,
            IconAffiliates,
            IconLeaderboard,
            IconSignOut,
            IconBattles,
            IconUnbox,
            IconUpgrader,
            IconCrash,
            IconBlackjack,
            IconRoll,
            IconDuels,
            IconMines,
            IconTowers,
            AuthButton,
            NavbarHomeDropdown,
            NavbarCashier,
            NavbarUserDropdown
        },
        methods: {
            ...mapActions([
                'notificationShow', 
                'modalsSetShow', 
                'generalSetSidebarMobile', 
                'authLogoutUser'
            ]),
            navbarVaultButton() {
                this.modalsSetShow('Vault');
                this.generalSetSidebarMobile(null);
            }
        },
        computed: {
            ...mapGetters([
                'generalSidebarMobile', 
                'authUser'
            ])
        }
    }
</script>

<style scoped>
    nav#navbar {
        width: calc(100% - 325px);
        height: 100px;
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        top: 0;
        left: 0;
        padding: 0 50px;
        background: rgba(13, 49, 78, 0.36);
        z-index: 50;
    }

    nav#navbar .navbar-left,
    nav#navbar .navbar-right  {
        width: calc(50% - 176px);
        display: flex;
        align-items: center;
        z-index: 1;
    }

    nav#navbar .navbar-right  {
        justify-content: flex-end;
    }

    nav#navbar .navbar-left button.button-chat {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: rgba(41, 81, 113, 0.29);
        border: 1px solid rgba(41, 105, 158, 0.30);
        display: none;
    }

    nav#navbar .navbar-left button.button-chat .button-inner  {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    nav#navbar .navbar-left button.button-chat .button-inner svg {
        width: 14px;
        margin-top: 2px;
    }

    nav#navbar .navbar-left a.link-logo {
        display: flex;
        align-items: center;
        margin-right: 30px;
    }

    nav#navbar .navbar-left a.link-logo img {
        height: 41px;
    }

    nav#navbar .navbar-left a.link-home {
        width: 50px;
        height: 50px;
        position: relative;
        margin-right: 10px;
        padding: 1px;
        z-index: 1;
    }

    nav#navbar .navbar-left a.link-home::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 133, 255, 0) 0%, #328adb 100%);
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    nav#navbar .navbar-left a.link-home::after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        background: #061628;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    nav#navbar .navbar-left a.link-home .link-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(255deg, rgba(59, 126, 183, 0.5) -70%, rgba(20, 80, 129, 0.5) 90%);
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        transition: background 0.3s ease;
    } 

    nav#navbar .navbar-left a.link-home:hover .link-inner {
        background: linear-gradient(255deg, #3b7eb7 -70%, #145081 90%);
    }

    nav#navbar .navbar-left a.link-home .link-inner svg {
        fill: #6e95b6;
        transition: fill 0.3s ease;
    }

    nav#navbar .navbar-left a.link-home:hover .link-inner svg {
        fill: #ffffff;
    }

    nav#navbar .navbar-mid button.auth-button {
        display: none;
    }

    nav#navbar .right-auth {
        display: flex;
        align-items: center;
    }

    nav#navbar .navbar-right button.button-toggle {
        width: 36px;
        height: 28px;
        display: none;
        filter: drop-shadow(0px 1.8px 1.8px rgba(0, 0, 0, 0.10));
    }

    nav#navbar .navbar-right button.button-toggle .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #214059;
        clip-path: polygon(4px 0, calc(100% - 4px) 0, 100% 25%, 100% 75%, calc(100% - 4px) 100%, 4px 100%, 0 75%, 0 25%);
    }

    nav#navbar .navbar-right button.button-toggle .button-inner svg {
        fill: #5b7b95;
        transition: fill 0.3s ease;
    }

    nav#navbar .navbar-right button.button-toggle:hover .button-inner svg {
        fill: #ffffff;
    }

    nav#navbar .navbar-mobile {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        transform: translate(100%, 0);
        padding: 95px 21px 0 21px;
        overflow-y: scroll;
        background-color: #021423;
        z-index: 0;
        transition: transform 0.3s ease;
    }

    nav#navbar .navbar-mobile a,
    nav#navbar .navbar-mobile button {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
        color: #bbbfd0;
        border-bottom: 1px solid #102b3f;
    }

    nav#navbar .navbar-mobile .mobile-user a,
    nav#navbar .navbar-mobile .mobile-games a,
    nav#navbar .navbar-mobile .mobile-user button {
        margin-top: 25px;
    }

    nav#navbar .navbar-mobile a.router-link-exact-active,
    nav#navbar .navbar-mobile a.link-blackjack.router-link-active {
        background: linear-gradient(250deg, #00ffc2 0%, #00aa6d 100%), #a6cae8;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    nav#navbar .navbar-mobile a::before,
    nav#navbar .navbar-mobile button::before {
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #102b3f;
    }

    nav#navbar .navbar-mobile a svg,
    nav#navbar .navbar-mobile button svg {
        width: 13px;
        margin-right: 8px;
        fill: #bbbfd0;
    }

    nav#navbar .navbar-mobile a.router-link-exact-active svg,
    nav#navbar .navbar-mobile a.link-blackjack.router-link-active svg {
        fill: #00ffc2;
    }

    nav#navbar .mobile-games {
        margin-top: 40px;
        font-size: 16px;
        font-weight: 800;
        color: #ffffff;
    }

    @media only screen and (max-width: 1750px) {

        nav#navbar {
            padding: 0 10px;
        }

    }

    @media only screen and (max-width: 1650px) {

        nav#navbar .left-rakeback,
        nav#navbar .right-links {
            display: none;
        }

    }

    @media only screen and (max-width: 1500px) {

        nav#navbar {
            width: 100%;
        }

    }

    @media only screen and (max-width: 1175px) {

        nav#navbar {
            height: 80px;
            padding: 0 20px;
            background: rgba(9, 40, 65, 0.48);
            border-bottom: 1px solid rgba(27, 64, 92, 0.35);
        }

        nav#navbar .navbar-left, 
        nav#navbar .navbar-right {
            width: auto;
        }

        nav#navbar .navbar-left button.button-chat,
        nav#navbar .navbar-right button.button-toggle {
            display: block;
        }

        nav#navbar .navbar-left a.link-logo,
        nav#navbar .navbar-left a.link-home,
        nav#navbar .navbar-left .navbar-home-dropdown,
        nav#navbar .navbar-right button.auth-button,
        nav#navbar .right-auth {
            display: none;
        }

        nav#navbar .navbar-mid button.auth-button {
            display: block;
        }

        nav#navbar .navbar-mobile.mobile-open {
            transform: translate(0, 0);
        }

    }
</style>
