<template>
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.34391 3.43573C8.2927 3.43573 9.06184 2.66662 9.06184 1.71787C9.06184 0.769115 8.2927 0 7.34391 0C6.39512 0 5.62598 0.769115 5.62598 1.71787C5.62598 2.66662 6.39512 3.43573 7.34391 3.43573Z" />
        <path d="M7.34412 14.6872C7.91333 14.6872 8.37477 14.2258 8.37477 13.6566C8.37477 13.0874 7.91333 12.626 7.34412 12.626C6.77491 12.626 6.31348 13.0874 6.31348 13.6566C6.31348 14.2258 6.77491 14.6872 7.34412 14.6872Z" />
        <path d="M3.12242 5.01084C3.97639 5.01084 4.66867 4.31859 4.66867 3.46465C4.66867 2.61071 3.97639 1.91846 3.12242 1.91846C2.26845 1.91846 1.57617 2.61071 1.57617 3.46465C1.57617 4.31859 2.26845 5.01084 3.12242 5.01084Z" />
        <path d="M11.565 12.7657C12.0394 12.7657 12.424 12.3812 12.424 11.9068C12.424 11.4324 12.0394 11.0479 11.565 11.0479C11.0906 11.0479 10.7061 11.4324 10.7061 11.9068C10.7061 12.3812 11.0906 12.7657 11.565 12.7657Z" />
        <path d="M1.37401 9.06091C2.13286 9.06091 2.74802 8.44576 2.74802 7.68695C2.74802 6.92813 2.13286 6.31299 1.37401 6.31299C0.615166 6.31299 0 6.92813 0 7.68695C0 8.44576 0.615166 9.06091 1.37401 9.06091Z" />
        <path d="M13.3132 8.37389C13.6925 8.37389 13.9999 8.06644 13.9999 7.68719C13.9999 7.30793 13.6925 7.00049 13.3132 7.00049C12.9339 7.00049 12.6265 7.30793 12.6265 7.68719C12.6265 8.06644 12.9339 8.37389 13.3132 8.37389Z" />
        <path d="M2.27283 11.0581C1.80294 11.528 1.80294 12.2883 2.27283 12.7581C2.74217 13.228 3.50359 13.228 3.97293 12.7581C4.44282 12.2883 4.44282 11.528 3.97293 11.0581C3.50359 10.5877 2.74273 10.5832 2.27283 11.0581Z" />
        <path d="M11.5644 3.98171C11.8488 3.98171 12.0794 3.75113 12.0794 3.46669C12.0794 3.18224 11.8488 2.95166 11.5644 2.95166C11.2799 2.95166 11.0493 3.18224 11.0493 3.46669C11.0493 3.75113 11.2799 3.98171 11.5644 3.98171Z" />
    </svg>
</template>