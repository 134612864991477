<template>
    <div class="notifications">
        <TransitionGroup name="slide">

            <NotificationsElement 
                v-for="notification in notifications" 
                :key="notification.id" 
                :notification="notification" 
            />

        </TransitionGroup>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import NotificationsElement from '@/components/notifications/NotificationsElement.vue';

    export default {
        components: {
            NotificationsElement
        },
        computed: {
            ...mapGetters([
                'notifications'
            ])
        }
    }
</script>

<style scoped>
    .notifications {
        position: fixed;
        right: 35px;
        top: 50px;
        z-index: 103;
    }

    .notifications .slide-enter-active,
    .notifications .slide-leave-active {
        transition: all 0.4s;
    }

    .notifications .slide-enter-from,
    .notifications .slide-leave-to {
        opacity: 0;
        transform: translate(50px, 0);
    }
</style>
