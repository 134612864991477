<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7199 7.66873C14.4 4.54874 11.92 2.06876 8.79998 1.66875V0.46875H7.19998V1.66875C4.07999 2.06876 1.60001 4.54874 1.27998 7.66873H0V9.26874H1.2C1.60001 12.3887 4.07999 14.8687 7.11999 15.1887V16.4687H8.72V15.1887C11.84 14.7887 14.32 12.3087 14.64 9.26874H16V7.66873H14.7199ZM13.12 9.26874C12.8 11.5087 11.04 13.2687 8.79998 13.5887V12.4687H7.19998V13.5887C4.96 13.2687 3.19998 11.5087 2.87999 9.26874H4V7.66873H2.87999C3.19998 5.42875 4.96 3.66873 7.19998 3.34874V4.46875H8.79998V3.34874C11.04 3.66873 12.8 5.42875 13.12 7.66873H12V9.26874H13.12Z" fill="black"/>
        <path d="M14.7199 7.66873C14.4 4.54874 11.92 2.06876 8.79998 1.66875V0.46875H7.19998V1.66875C4.07999 2.06876 1.60001 4.54874 1.27998 7.66873H0V9.26874H1.2C1.60001 12.3887 4.07999 14.8687 7.11999 15.1887V16.4687H8.72V15.1887C11.84 14.7887 14.32 12.3087 14.64 9.26874H16V7.66873H14.7199ZM13.12 9.26874C12.8 11.5087 11.04 13.2687 8.79998 13.5887V12.4687H7.19998V13.5887C4.96 13.2687 3.19998 11.5087 2.87999 9.26874H4V7.66873H2.87999C3.19998 5.42875 4.96 3.66873 7.19998 3.34874V4.46875H8.79998V3.34874C11.04 3.66873 12.8 5.42875 13.12 7.66873H12V9.26874H13.12Z" fill="url(#icon-terminal-gradient-1)"/>
        <path d="M8.00009 10.8688C9.32557 10.8688 10.4001 9.79432 10.4001 8.46884C10.4001 7.14336 9.32557 6.06885 8.00009 6.06885C6.67461 6.06885 5.6001 7.14336 5.6001 8.46884C5.6001 9.79432 6.67461 10.8688 8.00009 10.8688Z" fill="black"/>
        <path d="M8.00009 10.8688C9.32557 10.8688 10.4001 9.79432 10.4001 8.46884C10.4001 7.14336 9.32557 6.06885 8.00009 6.06885C6.67461 6.06885 5.6001 7.14336 5.6001 8.46884C5.6001 9.79432 6.67461 10.8688 8.00009 10.8688Z" fill="url(#icon-terminal-gradient-2)"/>
        <defs>
            <linearGradient id="icon-terminal-gradient-1" x1="34.916" y1="-22.0627" x2="3.69072" y2="-21.0296" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EAD621"/>
                <stop offset="1" stop-color="#FF8E26"/>
            </linearGradient>
            <linearGradient id="icon-terminal-gradient-2" x1="16.0749" y1="-0.690583" x2="6.70731" y2="-0.380654" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EAD621"/>
                <stop offset="1" stop-color="#FF8E26"/>
            </linearGradient>
        </defs>
    </svg>
</template>