<template>
    <div class="home-banner-user">
        <div class="user-inner">
            <div class="inner-knight">
                <img src="@/assets/img/knight.png" />
            </div>
            <div v-if="authUser.user === null" class="inner-guest">
                <div class="guest-title">SIGN IN TO START</div>
                <AuthButton />
            </div>
            <div v-else :class="['inner-auth', `auth-${homeGetLevelColor}`]">
                <div class="auth-title">WELCOME BACK,</div>
                <div class="auth-info">
                    <div class="info-avatar">
                        <AvatarImage :image="authUser.user.avatar" />
                    </div>
                    <span v-html="authUser.user.username"></span>
                </div>
                <div class="auth-level">
                    <div class="level-box">
                        <div class="box-inner">{{homeGetLevel >= 99 ? 99 : homeGetLevel}}</div>
                    </div>
                    <div class="level-progress">
                        <div class="progress-bar">
                            <div class="bar-inner" :style=" { 'width': `${(100 / homeGetLevelBet) * homeGetLevelProgress}%` }"></div>
                        </div>
                        <div class="progress-text">
                            Current XP progress
                            <div class="text-value">{{((100 / homeGetLevelBet) * homeGetLevelProgress).toFixed(2)}}%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AuthButton from '@/components/AuthButton.vue';
    import AvatarImage from '@/components/AvatarImage.vue';

    export default {
        components: {
            AuthButton,
            AvatarImage
        },
        computed: {
            ...mapGetters([
                'authUser'
            ]),
            homeGetLevel() {
                let level = Math.floor(Math.pow(this.authUser.user.xp / 1000 / 100, 1 / 3));
                return level >= 100 ? 100 : level; 
            },
            homeGetLevelColor() {
                let color = '';

                if(this.homeGetLevel >= 2 && this.homeGetLevel < 26) { color = 'blue'; }
                else if(this.homeGetLevel >= 26 && this.homeGetLevel < 51) { color = 'green'; }
                else if(this.homeGetLevel >= 51 && this.homeGetLevel < 76) { color = 'orange'; }
                else if(this.homeGetLevel >= 76 && this.homeGetLevel < 100) { color = 'red'; }
                else if(this.homeGetLevel >= 100) { color = 'purple'; }

                return color;
            },
            homeGetLevelBet() {
                return this.homeGetLevel >= 100 ? 2970100 : (Math.pow(this.homeGetLevel + 1, 3) - Math.pow(this.homeGetLevel, 3)) * 100;
            },
            homeGetLevelProgress() {
                return Math.floor((this.homeGetLevel >= 100 ? 2970100 * 1000 : this.authUser.user.xp - (Math.pow(this.homeGetLevel, 3) * 100 * 1000)) / 10) / 100;
            }
        }
    }
</script>

<style scoped>
    .home-banner-user {
        width: calc(50% - 12px);
        height: 175px;
        padding: 1px;
        border-radius: 15px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #01fbbe 100%);
    }

    .home-banner-user .user-inner {
        width: 100%;
        height: 100%;
        position: relative;
        padding: 22px 24px 22px 200px;
        border-radius: 14px;
        background: linear-gradient(280deg, rgba(2, 23, 41, 0) 50%, rgba(1, 251, 190, 0.3) 100%), 
                    radial-gradient(100% 100% at 50% 0%, #062641 0%, #021729 100%);
        z-index: 1;
    }

    .home-banner-user .user-inner::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 14px;
        background-image: url('@/assets/img/home_banner.png');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
    }

    .home-banner-user .inner-knight {
        width: 180px;
        height: 194px;
        position: absolute;
        bottom: 0;
        left: 8px;
        overflow: hidden;
    }

    .home-banner-user .inner-knight img {
        width: 100%;
        transform: scaleX(-1);
    }

    .home-banner-user .inner-guest,
    .home-banner-user .inner-auth {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        z-index: 1;
    }

    .home-banner-user .inner-guest {
        justify-content: flex-start;
    }

    .home-banner-user .guest-title,
    .home-banner-user .auth-title {
        font-family: Rubik;
        font-size: 28px;
        font-weight: 800;
        color: #ffffff;
    }

    .home-banner-user .inner-guest button.auth-button {
        margin-top: 15px;
    }

    .home-banner-user .auth-info {
        display: flex;
        align-items: center;
    }

    .home-banner-user .auth-info span {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
    }

    .home-banner-user .inner-auth.auth-blue .auth-info span {
        color: #559ee4;
    }

    .home-banner-user .inner-auth.auth-green .auth-info span {
        color: #b8e92d;
    }

    .home-banner-user .inner-auth.auth-orange .auth-info span {
        color: #fca311;
    }

    .home-banner-user .inner-auth.auth-red .auth-info span {
        color: #ff4e4e;
    }

    .home-banner-user .inner-auth.auth-purple .auth-info span {
        color: #6953f1;
    }  

    .home-banner-user .info-avatar {
        width: 33px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-right: 10px;
        border-radius: 50%;
        border: 2px solid #9e9e9e;
        overflow: hidden;
    }

    .home-banner-user .inner-auth.auth-blue .info-avatar {
        border: 2px solid #559ee4;
    }

    .home-banner-user .inner-auth.auth-green .info-avatar {
        border: 2px solid #b8e92d;
    }

    .home-banner-user .inner-auth.auth-orange .info-avatar {
        border: 2px solid #fca311;
    }

    .home-banner-user .inner-auth.auth-red .info-avatar {
        border: 2px solid #ff4e4e;
    }

    .home-banner-user .inner-auth.auth-purple .info-avatar {
        border: 2px solid #6953f1;
    }  

    .home-banner-user .info-avatar .avatar-image {
        width: 26px;
        height: 26px;
    }

    .home-banner-user .auth-level {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .home-banner-user .level-box {
        width: 44px;
        height: 38px;
        position: relative;
        padding: 1px;
    }

    .home-banner-user .level-box::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #9e9e9e 100%);
        clip-path: polygon(7px 0, calc(100% - 7px) 0, 100% 25%, 100% 75%, calc(100% - 7px) 100%, 7px 100%, 0 75%, 0 25%);
    }

    .home-banner-user .inner-auth.auth-blue .level-box::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #559ee4 100%);
    }

    .home-banner-user .inner-auth.auth-green .level-box::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #b8e92d 100%);
    }

    .home-banner-user .inner-auth.auth-orange .level-box::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #fca311 100%);
    }

    .home-banner-user .inner-auth.auth-red .level-box::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #ff4e4e 100%);
    }

    .home-banner-user .inner-auth.auth-purple .level-box::before {
        background: linear-gradient(180deg, rgba(6, 36, 61, 0) 0%, #6953f1 100%);
    }  

    .home-banner-user .box-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 15px;
        color: #9e9e9e;
        background-color: #03121e;
        clip-path: polygon(7px 0, calc(100% - 7px) 0, 100% 25%, 100% 75%, calc(100% - 7px) 100%, 7px 100%, 0 75%, 0 25%);
    }

    .home-banner-user .inner-auth.auth-blue .box-inner {
        color: #559ee4;
    }

    .home-banner-user .inner-auth.auth-green .box-inner {
        color: #b8e92d;
    }

    .home-banner-user .inner-auth.auth-orange .box-inner {
        color: #fca311;
    }

    .home-banner-user .inner-auth.auth-red .box-inner {
        color: #ff4e4e;
    }

    .home-banner-user .inner-auth.auth-purple .box-inner {
        color: #6953f1;
    } 

    .home-banner-user .level-progress {
        width: calc(100% - 56px);
        margin-left: 12px;
    }

    .home-banner-user .progress-bar {
        width: 100%;
        height: 12px;
        padding: 3px;
        border-radius: 6px;
        background-color: #03121e;
    }

    .home-banner-user .bar-inner {
        height: 100%;
        border-radius: 3px;
        background: linear-gradient(90deg, #ffffff 0%, #9e9e9e 100%);
    }

    .home-banner-user .inner-auth.auth-blue .bar-inner {
        background: linear-gradient(90deg, #a4d0ff 0%, #559ee4 100%);
    }

    .home-banner-user .inner-auth.auth-green .bar-inner {
        background: linear-gradient(90deg, #e5ffa4 0%, #b8e92d 100%);
    }

    .home-banner-user .inner-auth.auth-orange .bar-inner {
        background: linear-gradient(90deg, #ffe5a4 0%, #fca311 100%);
    }

    .home-banner-user .inner-auth.auth-red .bar-inner {
        background: linear-gradient(90deg, #ffa4a4 0%, #ff4e4e 100%);
    }

    .home-banner-user .inner-auth.auth-purple .bar-inner {
        background: linear-gradient(90deg, #b5a4ff 0%, #6953f1 100%);
    }

    .home-banner-user .progress-text {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
        font-size: 14px;
        font-weight: 600;
        color: #6e95b6;
    }

    .home-banner-user .text-value {
        font-weight: 700;
        color: #ffffff;
    }

    @media only screen and (max-width: 1050px) {

        .home-banner-user {
            width: 100%;
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 600px) {

        .home-banner-user .user-inner {
            padding: 22px 20px;
        }

        .home-banner-user .inner-knight {
            display: none;
        }

    }
</style>
