<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.32477 6.35167L5.43975 8.46785L4.59412 9.31408L5.44095 10.1609L4.59472 11.0071L3.11351 9.52593L1.42045 11.219L0.574219 10.3728L2.26728 8.6791L0.786076 7.1985L1.63231 6.35227L2.47854 7.1979L3.32477 6.35167ZM0.900982 0.124023L3.02314 0.125819L10.0952 7.1985L10.9421 6.35227L11.7883 7.1985L10.3077 8.6797L12.0001 10.3728L11.1539 11.219L9.46085 9.52593L7.97965 11.0071L7.13341 10.1609L7.97965 9.31408L0.902777 2.23721L0.900982 0.124023ZM9.55301 0.124023L11.6734 0.125819L11.6746 2.23422L9.24899 4.6592L7.13282 2.54362L9.55301 0.124023Z" fill="black"/>
        <path d="M3.32477 6.35167L5.43975 8.46785L4.59412 9.31408L5.44095 10.1609L4.59472 11.0071L3.11351 9.52593L1.42045 11.219L0.574219 10.3728L2.26728 8.6791L0.786076 7.1985L1.63231 6.35227L2.47854 7.1979L3.32477 6.35167ZM0.900982 0.124023L3.02314 0.125819L10.0952 7.1985L10.9421 6.35227L11.7883 7.1985L10.3077 8.6797L12.0001 10.3728L11.1539 11.219L9.46085 9.52593L7.97965 11.0071L7.13341 10.1609L7.97965 9.31408L0.902777 2.23721L0.900982 0.124023ZM9.55301 0.124023L11.6734 0.125819L11.6746 2.23422L9.24899 4.6592L7.13282 2.54362L9.55301 0.124023Z" fill="url(#icon-versus-gradient-1)"/>
        <defs>
            <linearGradient id="icon-versus-gradient-1" x1="12.0001" y1="0.124023" x2="-1.37407" y2="3.49947" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
        </defs>
    </svg>
</template>