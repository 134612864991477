<template>
    <button v-on:click="upgraderItemButton()" class="upgrader-item-element" v-bind:class="{
        'element-selected': upgraderItemList.selected.some((element) => element._id === item._id) === true
    }" v-bind:disabled="upgraderRunning === true">
        <div class="element-inner">
            <div class="inner-name">{{item.name}}</div>
            <div class="inner-image">
                <img v-bind:src="item.image" />
            </div>
            <div class="inner-price">
                <img src="@/assets/img/icons/coin.svg" alt="icon" />
                <div class="price-value">
                    <span>{{ generalFormatAmount(item.amountFixed).split('.')[0] }}</span>
                    .{{ generalFormatAmount(item.amountFixed).split('.')[1] }}
                </div>
            </div>
        </div>
    </button>
</template>

<script>
    import mixins from '@/mixins';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        mixins: [
            mixins
        ],
        props: {
            item: Object
        },
        methods: {
            ...mapActions([
                'upgraderAddItemListSelected',
                'upgraderRemoveItemListSelected'
            ]),
            upgraderItemButton() {
                if(this.upgraderItemList.selected.some((element) => element._id === this.item._id) === true) { this.upgraderRemoveItemListSelected(this.item); }
                else if(this.upgraderItemList.selected.length < 4) { this.upgraderAddItemListSelected(this.item); } 
            }
        },
        computed: {
            ...mapGetters([
                'upgraderRunning',
                'upgraderItemList'
            ])
        }
    }
</script>

<style scoped>
    button.upgrader-item-element {
        width: calc(14.28% - 4.28px);
        position: relative;
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 1px;
        z-index: 1;
    }

    button.upgrader-item-element:nth-child(7n) {
        margin-right: 0;
    }

    button.upgrader-item-element:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(223deg, rgba(66, 107, 252, 0.35) 0%, rgb(66, 107, 252, 0.6) 100%);
        z-index: -1;
    }

    button.upgrader-item-element.element-selected:before {
        background: linear-gradient(223deg, rgba(46, 202, 146, 0.35) 0%, rgb(46, 202, 146, 0.6) 100%);
    }

    button.upgrader-item-element .element-inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        background: radial-gradient(80% 80% at 50.00% 50.00%, rgb(66, 107, 252, 0.2) 0%, rgba(0, 0, 0, 0.00) 100%), 
                    linear-gradient(223deg, rgba(5, 29, 48, 0.35) 0%, rgba(31, 99, 153, 0.09) 50%, rgba(5, 29, 48, 0.35) 100%),
                    repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.25) 5px, rgba(2, 21, 36, 0.25) 10px), #042037;
    }

    button.upgrader-item-element.element-selected .element-inner {
        background: radial-gradient(80% 80% at 50.00% 50.00%, rgb(46, 202, 146, 0.2) 0%, rgba(0, 0, 0, 0.00) 100%), 
                    linear-gradient(223deg, rgba(5, 29, 48, 0.35) 0%, rgba(31, 99, 153, 0.09) 50%, rgba(5, 29, 48, 0.35) 100%),
                    repeating-linear-gradient(-55deg, transparent, transparent 5px, rgba(2, 21, 36, 0.25) 5px, rgba(2, 21, 36, 0.25) 10px), #042037;
    }

    button.upgrader-item-element .inner-name {
        height: 38px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #5e768e;
    }

    button.upgrader-item-element .inner-image {
        height: 74px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }

    button.upgrader-item-element .inner-image img {
        width: 106px;
    }

    button.upgrader-item-element .inner-price {
        display: flex;
        align-items: center;
        margin-top: 16px;
    }

    button.upgrader-item-element .inner-price img {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }

    button.upgrader-item-element .price-value {
        font-size: 10px;
        font-weight: 600;
        color: #bbbfd0;
    }

    button.upgrader-item-element .price-value span {
        font-size: 14px;
        font-weight: 800;
        color: #ffffff;
    }

    @media only screen and (max-width: 1220px) {

        button.upgrader-item-element {
            width: calc(16.66% - 4.16px);
        }

        button.upgrader-item-element:nth-child(7n) {
            margin-right: 5px;
        }

        button.upgrader-item-element:nth-child(6n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 900px) {

        button.upgrader-item-element {
            width: calc(20% - 4px);
        }

        button.upgrader-item-element:nth-child(6n) {
            margin-right: 5px;
        }

        button.upgrader-item-element:nth-child(5n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 750px) {

        button.upgrader-item-element {
            width: calc(25% - 3.75px);
        }

        button.upgrader-item-element:nth-child(5n) {
            margin-right: 5px;
        }

        button.upgrader-item-element:nth-child(4n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 600px) {

        button.upgrader-item-element {
            width: calc(33.33% - 3.33px);
        }

        button.upgrader-item-element:nth-child(4n) {
            margin-right: 5px;
        }

        button.upgrader-item-element:nth-child(3n) {
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 450px) {

        button.upgrader-item-element {
            width: calc(50% - 2.5px);
        }

        button.upgrader-item-element:nth-child(3n) {
            margin-right: 5px;
        }

        button.upgrader-item-element:nth-child(2n) {
            margin-right: 0;
        }

    }
</style>