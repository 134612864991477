<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8972 8.01057L10.7603 6.87354L8.40832 9.22551C7.98847 9.64535 7.46152 9.91908 6.88916 10.0215L8.38772 11.5201C8.52495 11.6574 8.74759 11.6574 8.88483 11.5201L11.8972 8.50777C12.0346 8.37044 12.0346 8.1478 11.8972 8.01057Z" fill="url(#icon-group-gradient-1)"/>
        <path d="M10.2964 6.3429C10.6927 5.94668 10.6928 5.3019 10.2969 4.9054C10.2965 4.90495 10.296 4.90449 10.2955 4.90403C10.1976 4.80617 10.0832 4.73073 9.95855 4.68029C10.0666 4.40701 10.0894 3.94488 9.73151 3.58692C9.7306 3.58601 9.72968 3.585 9.72867 3.58408C9.63118 3.48723 9.51747 3.41252 9.3937 3.36235C9.50173 3.08927 9.52461 2.62704 9.16693 2.26927C9.16666 2.26899 9.16648 2.26881 9.16629 2.26862C9.06824 2.17058 8.95372 2.09496 8.82894 2.04451C8.93696 1.77124 8.95976 1.30901 8.6019 0.951059C8.6018 0.951059 8.6018 0.950967 8.60171 0.950967C8.60162 0.950876 8.60153 0.950784 8.60144 0.950693C8.20494 0.554196 7.55989 0.554196 7.16339 0.950693L6.49683 1.61717L6.83767 1.958C7.40563 2.52597 7.40563 3.45006 6.83776 4.01802C6.6466 4.20909 6.41178 4.34037 6.15581 4.40289C6.09457 4.65246 5.96676 4.88902 5.77204 5.08365C5.58116 5.27453 5.34671 5.40572 5.09101 5.46834C5.02848 5.72394 4.8972 5.95849 4.70642 6.14927C4.51554 6.34015 4.28108 6.47134 4.02539 6.53396C3.96286 6.78956 3.83158 7.02411 3.64079 7.21499C3.36569 7.49 2.99986 7.6416 2.61078 7.6416C2.2217 7.6416 1.85587 7.49009 1.58077 7.2149L1.23993 6.87406L0.102992 8.01109C-0.0343307 8.14842 -0.0343307 8.37097 0.102992 8.50829L3.1153 11.5206C3.25263 11.6579 3.47518 11.6578 3.6125 11.5206L5.76884 9.36436H6.37635C6.95585 9.36436 7.50066 9.13861 7.91043 8.72893L10.2964 6.3429Z" fill="url(#icon-group-gradient-2)"/>
        <path d="M2.07826 6.71721C2.37249 7.01153 2.84964 7.01153 3.14388 6.71721C3.43821 6.42297 3.43821 5.94591 3.14388 5.65158C3.43821 5.94591 3.91527 5.94591 4.2096 5.65158C4.50383 5.35734 4.50383 4.88019 4.2096 4.58596C4.50383 4.88019 4.98098 4.88019 5.27522 4.58596C5.56946 4.29172 5.56946 3.81457 5.27522 3.52033C5.56946 3.81457 6.04661 3.81457 6.34085 3.52033C6.63517 3.226 6.63517 2.74894 6.34085 2.45462L4.83835 0.952123C4.54411 0.657886 4.06696 0.657886 3.77273 0.952123C3.47849 1.24636 3.47849 1.72351 3.77273 2.01775L3.96095 2.20606C3.66671 1.91173 3.18956 1.91173 2.89533 2.20606C2.60109 2.5003 2.60109 2.97745 2.89533 3.27169L3.27187 3.64823C2.97763 3.35399 2.50048 3.35399 2.20624 3.64823C1.91191 3.94246 1.91191 4.41961 2.20624 4.71385L2.58278 5.09048C2.28845 4.79615 1.81139 4.79615 1.51706 5.09048C1.22283 5.38472 1.22283 5.86178 1.51706 6.1561L2.07826 6.71721Z" fill="url(#icon-group-gradient-3)"/>
        <path d="M6.66638 0.452784C6.71838 0.400785 6.7734 0.352996 6.83062 0.308595L6.625 0.102977C6.51807 -0.00404281 6.35448 -0.0305003 6.21926 0.0370625L5.35156 0.470911L5.99991 1.11935L6.66638 0.452784Z" fill="url(#icon-group-gradient-4)"/>
        <defs>
            <linearGradient id="icon-group-gradient-1" x1="12.0002" y1="6.87354" x2="6.0504" y2="8.44267" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-group-gradient-2" x1="10.5937" y1="0.65332" x2="-1.89667" y2="3.60932" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-group-gradient-3" x1="6.56159" y1="0.731445" x2="0.277643" y2="2.03791" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-group-gradient-4" x1="6.83062" y1="0" x2="5.16387" y2="0.539744" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
        </defs>
    </svg>
</template>