<template>
    <div class="upgrader-items">
        <div class="items-header">
            <UpgraderFilterSearch />

            <div class="header-filters">
                <UpgraderFilterAmount />
                <UpgraderFilterSort />
            </div>
        </div>
        <div class="items-content">
            <Transition name="fade" mode="out-in">
                <div v-if="upgraderItemList.data === null || upgraderItemList.loading === true" class="content-loading" key="loading">

                    <LoadingAnimation />

                </div>
                <div v-else-if="upgraderItemList.data.length > 0" class="content-list" key="data">

                    <UpgraderItemElement v-for="item in upgraderItemList.data" v-bind:key="item._id" v-bind:item="item" />

                </div>
                <div v-else class="content-empty" key="empty">No boxes found.</div>
            </Transition>
        </div>
        <div class="items-pagination">
            <button v-on:click="upgraderSetPage(upgraderItemList.page - 1)" class="button-prev" v-bind:disabled="upgraderItemList.page <= 1">
                <div class="button-inner">
                    <IconLeftGradient />
                </div>
            </button>
            <div class="pagination-info">
                PAGE <span class="gradient-green">{{upgraderItemList.page}}</span> / {{Math.ceil(upgraderItemList.count / 105) <= 0 ? '1' : Math.ceil(upgraderItemList.count / 105)}}
            </div>
            <button v-on:click="upgraderSetPage(upgraderItemList.page + 1)" class="button-next" v-bind:disabled="upgraderItemList.page >= Math.ceil(upgraderItemList.count / 105)">
                <div class="button-inner">
                    <IconRightGradient />
                </div>
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation.vue';
    import IconLeftGradient from '@/components/icons/IconLeftGradient.vue';
    import IconRightGradient from '@/components/icons/IconRightGradient.vue';
    import UpgraderFilterSearch from '@/components/upgrader/UpgraderFilterSearch.vue';
    import UpgraderFilterAmount from '@/components/upgrader/UpgraderFilterAmount.vue';
    import UpgraderFilterSort from '@/components/upgrader/UpgraderFilterSort.vue';
    import UpgraderItemElement from '@/components/upgrader/UpgraderItemElement.vue';

    export default {
        components: {
            LoadingAnimation,
            IconLeftGradient,
            IconRightGradient,
            UpgraderFilterSearch,
            UpgraderFilterAmount,
            UpgraderFilterSort,
            UpgraderItemElement
        },
        methods: {
            ...mapActions([
                'upgraderSetItemListPage',
                'upgraderGetItemListSocket'
            ]),
            upgraderSetPage(page) {
                if(this.upgraderItemList.page === page) { return; }
                this.upgraderSetItemListPage(page);

                const data = { page: this.upgraderItemList.page, search: this.upgraderFilterSearch, select: this.upgraderFilterAmount, sort: this.upgraderFilterSort };
                this.upgraderGetItemListSocket(data);
            }
        },
        computed: {
            ...mapGetters([
                'socketUpgrader',
                'upgraderFilterSearch',
                'upgraderFilterAmount',
                'upgraderFilterSort',
                'upgraderItemList'
            ])
        },
        created() {
            const data = { page: this.upgraderItemList.page, search: this.upgraderFilterSearch, select: this.upgraderFilterAmount, sort: this.upgraderFilterSort };
            this.upgraderGetItemListSocket(data);
        }
    }
</script>

<style scoped>
    .upgrader-items {
        width: 1200px;
        margin-top: 35px;
        padding-top: 20px;
        border-top: 1px solid rgba(41, 114, 169, 0.16);
    }

    .upgrader-items .items-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .upgrader-items .header-filters {
        display: flex;
    }

    .upgrader-items .items-content {
        width: 100%;
        margin-top: 20px;
    }

    .upgrader-items .content-loading {
        width: 100%;
        height: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .upgrader-items .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .upgrader-items .content-loading.fade-leave-to {
        opacity: 0;
    }

    .upgrader-items .content-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .upgrader-items .content-empty {
        width: 100%;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }

    .upgrader-items .content-list.fade-enter-active,
    .upgrader-items .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .upgrader-items .content-list.fade-enter-from,
    .upgrader-items .content-empty.fade-enter-from {
        opacity: 0;
    }

    .upgrader-items .items-pagination {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
    }

    .upgrader-items .items-pagination button {
        width: 52px;
        height: 37px;
        position: relative;
        padding: 1px;
        z-index: 1;
    }

    .upgrader-items .items-pagination button:disabled {
        cursor: not-allowed;
    }

    .upgrader-items .items-pagination button:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, #01fabd 0%, #01b376 100%);
        clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    .upgrader-items .items-pagination button:disabled:before {
        background: #092a45;
    }

    .upgrader-items .items-pagination button:after {
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        position: absolute;
        top: 1px;
        left: 1px;
        background: #07253c;
        clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
        z-index: -1;
    }

    .upgrader-items .items-pagination button .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(255deg, rgba(0, 255, 194, 0.1) 0%, rgba(0, 170, 109, 0.1) 100%), rgba(0, 0, 0, 0.1);
        clip-path: polygon(5px 0, calc(100% - 5px) 0, 100% 25%, 100% 75%, calc(100% - 5px) 100%, 5px 100%, 0 75%, 0 25%);
    }

    .upgrader-items .items-pagination button:disabled .button-inner {
        background: rgba(3, 20, 34, 0.27);
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
    }

    .upgrader-items .items-pagination button.button-prev .button-inner svg {
        fill: url(#icon-left-gradient-1);
    }

    .upgrader-items .items-pagination button.button-next .button-inner svg {
        fill: url(#icon-right-gradient-1);
    }

    .upgrader-items .items-pagination button:disabled .button-inner svg {
        fill: #7a93ac;
    }

    .upgrader-items .pagination-info {
        font-size: 12px;
        font-weight: 800;
        color: #5e768e;
    }

    @media only screen and (max-width: 1220px) {

        .upgrader-items {
            width: 100%;
        }

    }

    @media only screen and (max-width: 750px) {

        .upgrader-items .items-header {
            flex-direction: column;
        }

    }

    @media only screen and (max-width: 500px) {

        .upgrader-items .header-filters {
            flex-direction: column;
        }

    }
</style>