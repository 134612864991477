<template>
    <div :class="['navbar-home-dropdown', { 
        'dropdown-open': navbarDropdown === true 
    }]">
        <button @click="navbarSetDropdown(!navbarDropdown)" class="button-toggle">
            <div class="button-inner">
                <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.1224 3.00476C16.3487 -1.5523 12.1703 1.6181 12.1703 1.6181C11.8994 1.8229 11.3997 1.99129 11.0602 1.99208L9.94096 1.99286C9.60145 1.99325 9.10176 1.82486 8.83117 1.62006C8.83117 1.62006 4.65239 -1.55113 1.87829 3.00593C-0.89503 7.56143 0.2226 12.5226 0.2226 12.5226C0.4184 13.7374 1.06376 14.548 2.28164 14.4462C3.49599 14.3447 6.13186 11.1747 6.13186 11.1747C6.34959 10.9135 6.80581 10.7001 7.14454 10.7001L13.8542 10.6989C14.1934 10.6989 14.6492 10.9124 14.8665 11.1736C14.8665 11.1736 17.5028 14.3436 18.7191 14.445C19.9354 14.5468 20.5823 13.7354 20.777 12.5214C20.777 12.5214 21.8962 7.56104 19.1224 3.00476ZM7.97865 6.714H6.53991V8.10066C6.53991 8.10066 6.23524 8.33327 5.76376 8.3274C5.29305 8.32035 5.08002 8.07364 5.08002 8.07364V6.71439H3.72116C3.72116 6.71439 3.5516 6.54052 3.50617 6.08274C3.46153 5.62496 3.69375 5.2549 3.69375 5.2549H5.13289V3.81615C5.13289 3.81615 5.42894 3.65756 5.85461 3.6697C6.28067 3.68301 6.59277 3.84318 6.59277 3.84318L6.5869 5.25451H7.94576C7.94576 5.25451 8.18307 5.55917 8.20265 5.92728C8.22223 6.29577 7.97865 6.714 7.97865 6.714ZM14.5779 8.2945C13.9478 8.2945 13.4399 7.78503 13.4399 7.15573C13.4399 6.52525 13.9478 6.01695 14.5779 6.01695C15.2053 6.01695 15.7171 6.52525 15.7171 7.15573C15.7171 7.78542 15.2053 8.2945 14.5779 8.2945ZM14.5779 5.1613C13.9478 5.1613 13.4399 4.65222 13.4399 4.02292C13.4399 3.39244 13.9478 2.88414 14.5779 2.88414C15.2053 2.88414 15.7171 3.39244 15.7171 4.02292C15.7171 4.65261 15.2053 5.1613 14.5779 5.1613ZM17.4139 6.84323C16.7838 6.84323 16.2759 6.33415 16.2759 5.70446C16.2759 5.07437 16.7838 4.56568 17.4139 4.56568C18.0412 4.56568 18.5531 5.07437 18.5531 5.70446C18.5531 6.33415 18.0412 6.84323 17.4139 6.84323Z" />
                </svg>
                GAMES
                <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5176 1.66411e-06L0.482354 8.43375e-08C0.0547936 9.58042e-09 -0.16302 0.516304 0.143533 0.822859L4.66115 5.34052C4.8467 5.52607 5.15325 5.52607 5.33888 5.34052L9.8565 0.822861C10.163 0.516306 9.94516 1.73887e-06 9.5176 1.66411e-06Z" />
                </svg>
            </div>
        </button>
        <div class="dropdown-menu">
            <div class="menu-inner">
                <RouterLink @click="navbarSetDropdown(false)" to="/battles">
                    <IconBattles />
                    <span class="text-green-gradient-exact">Battles</span>
                </RouterLink>
                <RouterLink @click="navbarSetDropdown(false)" to="/unbox">
                    <IconUnbox />
                    <span class="text-green-gradient-exact">Unbox</span>
                </RouterLink>
                <RouterLink @click="navbarSetDropdown(false)" to="/upgrader">
                    <IconUpgrader />
                    <span class="text-green-gradient-exact">Upgrader</span>
                </RouterLink>
                <RouterLink @click="navbarSetDropdown(false)" to="/crash">
                    <IconCrash />
                    <span class="text-green-gradient-exact">Crash</span>
                </RouterLink>
                <RouterLink @click="navbarSetDropdown(false)" to="/roll">
                    <IconRoll />
                    <span class="text-green-gradient-exact">Roll</span>
                </RouterLink>
                <RouterLink @click="navbarSetDropdown(false)" to="/blackjack">
                    <IconBlackjack />
                    <span class="text-green-gradient-exact">Blackjack</span>
                </RouterLink>
                <RouterLink @click="navbarSetDropdown(false)" to="/duels">
                    <IconDuels />
                    <span class="text-green-gradient-exact">Dice Duels</span>
                </RouterLink>
                <RouterLink @click="navbarSetDropdown(false)" to="/mines">
                    <IconMines />
                    <span class="text-green-gradient-exact">Mines</span>
                </RouterLink>
                <RouterLink @click="navbarSetDropdown(false)" to="/towers">
                    <IconTowers />
                    <span class="text-green-gradient-exact">Towers</span>
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script>
    import IconBattles from '@/components/icons/IconBattles.vue';
    import IconUnbox from '@/components/icons/IconUnbox.vue';
    import IconUpgrader from '@/components/icons/IconUpgrader.vue';
    import IconCrash from '@/components/icons/IconCrash.vue';
    import IconRoll from '@/components/icons/IconRoll.vue';
    import IconDuels from '@/components/icons/IconDuels.vue';
    import IconBlackjack from '@/components/icons/IconBlackjack.vue';
    import IconMines from '@/components/icons/IconMines.vue';
    import IconTowers from '@/components/icons/IconTowers.vue';

    export default {
        components: {
            IconBattles,
            IconUnbox,
            IconUpgrader,
            IconCrash,
            IconRoll,
            IconDuels,
            IconBlackjack,
            IconMines,
            IconTowers
        },
        data() {
            return {
                navbarDropdown: false
            }
        },
        methods: {
            navbarSetDropdown(value) {
                this.navbarDropdown = value;
            }
        },
        created() {
            let self = this;
            document.addEventListener('click', function(event) {
                if(!self.$el.contains(event.target) && self.navbarDropdown === true) {
                    self.navbarSetDropdown(false);
                }
            });
        }
    }
</script>

<style scoped>
    .navbar-home-dropdown {
        position: relative;
    }

    .navbar-home-dropdown button.button-toggle {
        width: 140px;
        height: 50px;
        position: relative;
        padding: 1px;
        background: linear-gradient(180deg, rgba(0, 133, 255, 0) 0%, #328adb 100%);
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        z-index: 1;
    }

    .navbar-home-dropdown button.button-toggle .button-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 800;
        color: #6e95b6;
        background: linear-gradient(255deg, rgba(59, 126, 183, 0.5) -70%, rgba(20, 80, 129, 0.5) 90%), #061628;
        clip-path: polygon(8px 0, calc(100% - 8px) 0, 100% 25%, 100% 75%, calc(100% - 8px) 100%, 8px 100%, 0 75%, 0 25%);
        transition: all 0.3s ease;
    }

    .navbar-home-dropdown.dropdown-open button.button-toggle .button-inner,
    .navbar-home-dropdown button.button-toggle:hover .button-inner {
        color: #ffffff;
        background: linear-gradient(255deg, #3b7eb7 -70%, #145081 90%), #061628;
    }

    .navbar-home-dropdown button.button-toggle .button-inner svg:first-of-type {
        margin-right: 8px;
        fill: #6e95b6;
        transition: fill 0.5s ease;
    }

    .navbar-home-dropdown button.button-toggle .button-inner svg:last-of-type {
        width: 10px;
        margin-left: 8px;
        fill: #6e95b6;
        transition: all 0.3s ease;
    }

    .navbar-home-dropdown.dropdown-open button.button-toggle .button-inner svg,
    .navbar-home-dropdown button.button-toggle:hover .button-inner svg {
        fill: #ffffff;
    }

    .navbar-home-dropdown.dropdown-open button.button-toggle .button-inner svg:last-of-type {
         transform: rotate(180deg);
    }

    .navbar-home-dropdown .dropdown-menu {
        height: 0;
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translate(-50%, 0);
        transition: height 0.2s ease;
        overflow: hidden;
    }

    .navbar-home-dropdown.dropdown-open .dropdown-menu {
       height: 481px;
    }

    .navbar-home-dropdown .menu-inner {
        width: 145px;
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        border-radius: 15px;
        background: radial-gradient(163% 163% at 50% -30%, rgba(49, 114, 168, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
                    linear-gradient(255deg, #07263d 0%, #07243a 100%),
                    linear-gradient(255deg, rgba(59, 126, 183, 0.15) 0%, rgba(20, 80, 129, 0.15) 100%);
    }

    .navbar-home-dropdown .menu-inner a {
        width: 100%;
        height: 49px;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
        border-top: 1px solid rgba(24, 72, 109, 0.5);
        transition: all 0.3s ease;
    }

    .navbar-home-dropdown .menu-inner a:hover {
        color: #ffffff;
    }

    .navbar-home-dropdown .menu-inner a:last-of-type {
        border-radius: 0 0 15px 15px;
    }

    .navbar-home-dropdown .menu-inner a.router-link-exact-active {
        background: linear-gradient(255deg, rgba(0, 255, 194, 0.15) 0%, rgba(0, 170, 109, 0.15) 100%);
    }

    .navbar-home-dropdown .menu-inner a.router-link-exact-active::before {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
        opacity: 0.35;
    }

    .navbar-home-dropdown .menu-inner a.router-link-exact-active::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(255deg, #00ffc2 0%, #00aa6d 100%);
        opacity: 0.35;
    }

    .navbar-home-dropdown .menu-inner a.router-link-exact-active:last-of-type::after {
        height: 0;
    }

    .navbar-home-dropdown .menu-inner a svg {
        margin-right: 8px;
        fill: #bbbfd0;
    }

    .navbar-home-dropdown .menu-inner a.router-link-exact-active svg {
        fill: #01f3b9;
    }
</style>
