<template>
    <div class="home-banner-rewards">
        <div class="rewards-inner">
            <img src="@/assets/img/rewards.png" alt="rewards" />
            <div class="inner-title">Free Rewards</div>
            <div class="inner-info">
                Explore all the rewards RBLXRoll has to offer.<br>
                Claim <span>Daily Cases</span> & earn <span>Rakeback</span> on all your bets.
            </div>
            <RouterLink class="link-rewards" to="/rewards">
                <div class="link-inner">VIEW DAILY REWARDS</div>
            </RouterLink>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style scoped>
    .home-banner-rewards {
        width: calc(50% - 12px);
        height: 175px;
        padding: 1px;
        border-radius: 15px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #01fbbe 100%);
        z-index: 1;
    }

    .home-banner-rewards .rewards-inner {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 22px 180px 22px 35px;
        border-radius: 14px;
        background: linear-gradient(280deg, rgba(2, 23, 41, 0) 50%, rgba(1, 251, 190, 0.3) 100%), 
                    radial-gradient(100% 100% at 50% 0%, #062641 0%, #021729 100%);
        z-index: 1;
    }

    .home-banner-rewards .rewards-inner img {
        height: 194px;
        position: absolute;
        bottom: 0;
        right: 10px;
    }

    .home-banner-rewards .inner-title {
        font-family: Rubik;
        font-size: 28px;
        font-weight: 800;
        color: #00FFC2;
    }

    .home-banner-rewards .inner-info {
        font-size: 12px;
        font-weight: 700;
        color: rgba(217, 217, 217, 0.75);
    }

    .home-banner-rewards .inner-info span {
        color: #ffffff;
    }

    .home-banner-rewards a.link-rewards {
        width: 200px;
        height: 40px;
        display: flex;
        padding: 1px;
        background: linear-gradient(180deg, rgba(0, 255, 194, 0), #00FFC2);
        clip-path: polygon(6px 0, calc(100% - 6px) 0, 100% 25%, 100% 75%, calc(100% - 6px) 100%, 6px 100%, 0 75%, 0 25%);
    }

    .home-banner-rewards a.link-rewards .link-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 800;
        color: #ffffff;
        background: linear-gradient(45deg, #00AA6D, #00FFC2);
        clip-path: polygon(6px 0, calc(100% - 6px) 0, 100% 25%, 100% 75%, calc(100% - 6px) 100%, 6px 100%, 0 75%, 0 25%);
    }

    @media only screen and (max-width: 1050px) {

        .home-banner-rewards {
            width: 100%;
            margin-right: 0;
        }

    }

    @media only screen and (max-width: 600px) {

        .home-banner-rewards .rewards-inner {
            padding: 22px 20px;
        }

        .home-banner-rewards .rewards-inner img {
            display: none;
        }

    }
</style>
