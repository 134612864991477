<template>
    <svg width="16" height="17" viewBox="0 0 16 17" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.469 3.32317C13.3233 3.61196 13.0761 3.82791 12.7717 3.92938C12.4647 4.03085 12.1395 4.00743 11.8507 3.86173L10.3417 3.10722C10.2142 3.04218 10.0685 3.03437 9.93322 3.08121C9.79793 3.12544 9.69126 3.2191 9.62621 3.34658L9.43368 3.73425L8.83008 3.43244L9.02261 3.04999C9.16831 2.76119 9.41287 2.54525 9.71988 2.44378C10.0269 2.34231 10.3521 2.36572 10.6383 2.50882L12.1499 3.26593C12.2774 3.32577 12.4179 3.33878 12.5532 3.29195C12.6885 3.24772 12.7977 3.15406 12.8628 3.02657C12.9434 2.86266 13.149 2.78981 13.3129 2.87567C13.482 2.95632 13.5497 3.15666 13.469 3.32317Z" />
        <path d="M7.90897 4.73864C7.948 4.75425 7.98702 4.76986 8.02605 4.78547C8.16394 4.84271 8.30183 4.90255 8.43973 4.9702C8.63746 5.06906 8.82219 5.17834 9.00431 5.29281C9.05374 5.32403 9.10057 5.35525 9.1474 5.38648C9.19944 5.4203 9.25408 5.45412 9.30611 5.49055L9.44921 5.20175C9.60531 4.88694 9.47783 4.50188 9.16301 4.34838L8.50477 4.01795C8.35387 3.9425 8.17955 3.9295 8.01824 3.98413C7.85694 4.03877 7.72685 4.15064 7.6514 4.30415L7.5083 4.59554C7.52911 4.60335 7.55253 4.61115 7.57334 4.61896C7.68522 4.65278 7.7971 4.69441 7.90897 4.73864Z" />
        <path d="M9.20995 6.2685C9.20735 6.26589 9.20735 6.26589 9.20995 6.2685C9.05125 6.13581 8.88213 6.01352 8.70261 5.89645C8.52309 5.77937 8.33577 5.67269 8.13803 5.57123C7.75037 5.3787 7.3471 5.233 6.94643 5.13673C6.49893 5.03006 6.05143 4.97803 5.60913 4.97803C3.51732 4.97803 1.56861 6.14621 0.592955 8.09232C-0.793778 10.8632 0.335382 14.2481 3.10885 15.6374C5.88231 17.0241 9.26719 15.8924 10.6539 13.1189C11.8351 10.7643 11.2419 7.94662 9.20995 6.2685ZM2.05254 8.82081C1.52698 9.86932 1.49056 11.0921 1.95107 12.1719C2.02392 12.3436 1.94326 12.5413 1.77415 12.6142C1.72992 12.6324 1.68569 12.6402 1.64146 12.6402C1.51137 12.6402 1.38649 12.5621 1.33185 12.4346C0.79329 11.1728 0.83752 9.74443 1.45153 8.51901C1.53479 8.3525 1.73773 8.28485 1.90424 8.36811C2.06815 8.45136 2.13579 8.6543 2.05254 8.82081Z" />
        <path d="M3.83992 14.175C3.39503 13.9539 3.00216 13.6573 2.67174 13.293C2.54685 13.1551 2.33351 13.1447 2.19562 13.2696C2.05773 13.3945 2.04732 13.6078 2.1722 13.7457C2.55986 14.1698 3.01777 14.5185 3.53552 14.776C3.58495 14.7994 3.63439 14.8125 3.68642 14.8125C3.8087 14.8125 3.92838 14.7448 3.98822 14.6277C4.07148 14.4612 4.00383 14.2583 3.83992 14.175Z" />
        <path d="M13.0423 0.495126C12.8575 0.469109 12.6858 0.593993 12.6572 0.776115L12.5167 1.68413C12.4881 1.86885 12.6156 2.04057 12.7977 2.06919C12.8159 2.07179 12.8315 2.07439 12.8497 2.07439C13.0136 2.07439 13.1567 1.95471 13.1828 1.7882L13.3233 0.880185C13.3519 0.695461 13.2244 0.523745 13.0423 0.495126Z" />
        <path d="M14.7128 1.23925C14.5619 1.12997 14.3511 1.1664 14.2445 1.3173L13.7215 2.05359C13.6148 2.2045 13.6487 2.41524 13.7996 2.52191C13.8594 2.56354 13.9271 2.58435 13.9947 2.58435C14.0988 2.58435 14.2028 2.53492 14.2679 2.44386L14.7908 1.70756C14.8975 1.55666 14.8637 1.34852 14.7128 1.23925Z" />
        <path d="M15.9614 2.57377C15.8756 2.40986 15.67 2.34742 15.5061 2.43328L14.6944 2.86257C14.5305 2.94842 14.468 3.15396 14.5539 3.31787C14.6137 3.43235 14.7308 3.49739 14.8531 3.49739C14.9051 3.49739 14.9598 3.48438 15.0092 3.45837L15.8209 3.02908C15.9848 2.94062 16.0473 2.73768 15.9614 2.57377Z" />
    </svg>
</template>