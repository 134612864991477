<template>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 0C6.01664 0 4.5666 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324961 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48913 0 7.5 0V0ZM10.2454 4.80493C10.4358 4.80474 10.6219 4.86103 10.7803 4.96668C10.9387 5.07233 11.0622 5.22259 11.1351 5.39844C11.2081 5.5743 11.2272 5.76784 11.1901 5.95457C11.153 6.14131 11.0614 6.31284 10.9267 6.44746C10.7921 6.58209 10.6206 6.67374 10.4339 6.71084C10.2471 6.74793 10.0536 6.72879 9.87772 6.65585C9.70187 6.5829 9.55161 6.45942 9.44596 6.30104C9.34031 6.14266 9.28402 5.9565 9.28421 5.76612C9.28421 5.5112 9.38548 5.26672 9.56574 5.08646C9.74599 4.9062 9.99048 4.80493 10.2454 4.80493ZM4.75461 4.80493C4.94499 4.80474 5.13115 4.86103 5.28953 4.96668C5.44791 5.07233 5.57139 5.22259 5.64434 5.39844C5.71728 5.5743 5.73642 5.76784 5.69933 5.95457C5.66223 6.14131 5.57057 6.31284 5.43595 6.44746C5.30133 6.58209 5.1298 6.67374 4.94306 6.71084C4.75633 6.74793 4.56279 6.72879 4.38693 6.65585C4.21108 6.5829 4.06082 6.45942 3.95517 6.30104C3.84952 6.14266 3.79323 5.9565 3.79342 5.76612C3.79369 5.51128 3.89504 5.26695 4.07524 5.08675C4.25544 4.90655 4.49977 4.8052 4.75461 4.80493ZM11.7237 9.4125C11.4089 11.2095 9.6375 12.4559 7.5 12.4559C5.3625 12.4559 3.59211 11.2095 3.27731 9.4125C3.27392 9.39426 3.27194 9.37578 3.27138 9.35724C3.27138 9.0977 3.58717 8.92993 3.86349 9.02467C4.92928 9.39276 6.1727 9.58026 7.50099 9.58026C8.82928 9.58026 10.0727 9.39276 11.1385 9.02467C11.4138 8.92599 11.7306 9.09671 11.7306 9.35724C11.7297 9.37581 11.7274 9.39429 11.7237 9.4125Z" fill="black" />
        <path d="M7.5 0C6.01664 0 4.5666 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324961 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C15 5.51088 14.2098 3.60322 12.8033 2.1967C11.3968 0.790176 9.48913 0 7.5 0V0ZM10.2454 4.80493C10.4358 4.80474 10.6219 4.86103 10.7803 4.96668C10.9387 5.07233 11.0622 5.22259 11.1351 5.39844C11.2081 5.5743 11.2272 5.76784 11.1901 5.95457C11.153 6.14131 11.0614 6.31284 10.9267 6.44746C10.7921 6.58209 10.6206 6.67374 10.4339 6.71084C10.2471 6.74793 10.0536 6.72879 9.87772 6.65585C9.70187 6.5829 9.55161 6.45942 9.44596 6.30104C9.34031 6.14266 9.28402 5.9565 9.28421 5.76612C9.28421 5.5112 9.38548 5.26672 9.56574 5.08646C9.74599 4.9062 9.99048 4.80493 10.2454 4.80493ZM4.75461 4.80493C4.94499 4.80474 5.13115 4.86103 5.28953 4.96668C5.44791 5.07233 5.57139 5.22259 5.64434 5.39844C5.71728 5.5743 5.73642 5.76784 5.69933 5.95457C5.66223 6.14131 5.57057 6.31284 5.43595 6.44746C5.30133 6.58209 5.1298 6.67374 4.94306 6.71084C4.75633 6.74793 4.56279 6.72879 4.38693 6.65585C4.21108 6.5829 4.06082 6.45942 3.95517 6.30104C3.84952 6.14266 3.79323 5.9565 3.79342 5.76612C3.79369 5.51128 3.89504 5.26695 4.07524 5.08675C4.25544 4.90655 4.49977 4.8052 4.75461 4.80493ZM11.7237 9.4125C11.4089 11.2095 9.6375 12.4559 7.5 12.4559C5.3625 12.4559 3.59211 11.2095 3.27731 9.4125C3.27392 9.39426 3.27194 9.37578 3.27138 9.35724C3.27138 9.0977 3.58717 8.92993 3.86349 9.02467C4.92928 9.39276 6.1727 9.58026 7.50099 9.58026C8.82928 9.58026 10.0727 9.39276 11.1385 9.02467C11.4138 8.92599 11.7306 9.09671 11.7306 9.35724C11.7297 9.37581 11.7274 9.39429 11.7237 9.4125Z" fill="url(#icon-emojis-gradient)" />
        <defs>
            <linearGradient id="icon-emojis-gradient" x1="15" y1="0" x2="-2.61795" y2="4.3177" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
        </defs>
    </svg>
</template>
