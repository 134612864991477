<template>
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8632 5.77683L11.0635 0.443781C10.9611 0.33072 10.8182 0.266724 10.6667 0.266724H7.46687C7.25675 0.266724 7.06583 0.39045 6.97943 0.58244C6.8941 0.775496 6.9293 1.00055 7.07009 1.15628L11.5488 6.13307L7.07009 11.1088C6.9293 11.2656 6.89304 11.4907 6.97943 11.6826C7.06583 11.8757 7.25675 11.9994 7.46687 11.9994H10.6667C10.8182 11.9994 10.9611 11.9344 11.0635 11.8234L15.8632 6.49039C16.0456 6.28773 16.0456 5.97842 15.8632 5.77683Z" fill="url(#icon-upgrader-gradient-1)"/>
        <path d="M8.93025 5.77683L4.13051 0.443781C4.02812 0.33072 3.88519 0.266724 3.73373 0.266724H0.533904C0.323782 0.266724 0.132859 0.39045 0.0464639 0.58244C-0.0388648 0.775496 -0.00366671 1.00055 0.137126 1.15628L4.61582 6.13307L0.137126 11.1088C-0.00366671 11.2656 -0.0399314 11.4907 0.0464639 11.6826C0.132859 11.8757 0.323782 11.9994 0.533904 11.9994H3.73373C3.88519 11.9994 4.02812 11.9344 4.13051 11.8234L8.93025 6.49039C9.11264 6.28773 9.11264 5.97842 8.93025 5.77683Z" fill="url(#icon-upgrader-gradient-2)"/>
        <defs>
            <linearGradient id="icon-upgrader-gradient-1" x1="6.93297" y1="117.935" x2="16" y2="117.935" gradientUnits="userSpaceOnUse">
                <stop stop-color="#618af1"/>
                <stop offset="1" stop-color="#1c63b6"/>
            </linearGradient>
            <linearGradient id="icon-upgrader-gradient-2" x1="6.10837e-07" y1="117.935" x2="9.06706" y2="117.935" gradientUnits="userSpaceOnUse">
                <stop stop-color="#618af1"/>
                <stop offset="1" stop-color="#1c63b6"/>
            </linearGradient>
        </defs>
    </svg>
</template>