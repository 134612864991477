<template>
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.66294 0.672527L0.117702 3.86324C0.0425426 3.93131 -1.12896e-07 4.02632 -1.08494e-07 4.12701L-1.55142e-08 6.25415C-9.40845e-09 6.39383 0.0822495 6.52075 0.209878 6.57818C0.338216 6.63491 0.487824 6.61151 0.591345 6.51791L3.89976 3.54062L7.20747 6.51791C7.3117 6.61151 7.4613 6.63561 7.58893 6.57818C7.71727 6.52075 7.79952 6.39383 7.79952 6.25415L7.79952 4.127C7.79952 4.02632 7.75627 3.93131 7.68253 3.86324L4.13729 0.672527C4.00257 0.55128 3.79695 0.55128 3.66294 0.672527Z" fill="url(#icon-upgrader-mode-gradient-1)"/>
        <path d="M3.66294 5.28135L0.117702 8.47207C0.0425426 8.54013 -1.12896e-07 8.63515 -1.08494e-07 8.73583L-1.55141e-08 10.863C-9.40844e-09 11.0027 0.0822495 11.1296 0.209878 11.187C0.338216 11.2437 0.487824 11.2203 0.591345 11.1267L3.89976 8.14945L7.20747 11.1267C7.3117 11.2203 7.4613 11.2444 7.58893 11.187C7.71727 11.1296 7.79952 11.0027 7.79952 10.863L7.79952 8.73583C7.79952 8.63515 7.75627 8.54013 7.68253 8.47207L4.13729 5.28135C4.00257 5.16011 3.79695 5.16011 3.66294 5.28135Z" fill="url(#icon-upgrader-mode-gradient-2)"/>
        <defs>
            <linearGradient id="icon-upgrader-mode-gradient-1" x1="-2.63469e-07" y1="0.581592" x2="1.37212" y2="7.82638" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-upgrader-mode-gradient-2" x1="-2.63469e-07" y1="5.19042" x2="1.37212" y2="12.4352" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
        </defs>
    </svg>
</template>