<template>
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.56714 9.33123L5.41346 10.0997L6.42682 9.76189C6.45064 9.75396 6.47542 9.74999 6.50023 9.74999C6.52501 9.74999 6.54983 9.75396 6.57365 9.76189L7.58701 10.0997L7.43333 9.33123C7.42039 9.26646 7.43562 9.19923 7.47526 9.14642L7.95066 8.51252L7.15116 8.3526C7.09516 8.3414 7.04525 8.30995 7.01098 8.26425L6.5002 7.58331L5.98949 8.26427C5.95522 8.30998 5.90531 8.34143 5.8493 8.35263L5.0498 8.51255L5.5252 9.14642C5.56484 9.19926 5.58008 9.26646 5.56714 9.33123Z" />
        <path d="M4.785 3.71429L4.42256 2.62699C4.38202 2.50534 4.44775 2.37386 4.56939 2.33333C4.69098 2.29282 4.82249 2.35854 4.86302 2.48016L5.27438 3.71429H6.26778V2.55357C6.26778 2.42537 6.37169 2.32143 6.49992 2.32143C6.62812 2.32143 6.73206 2.42537 6.73206 2.55357V3.71429H7.72546L8.13685 2.48016C8.17736 2.35857 8.30881 2.29282 8.43048 2.33333C8.55209 2.37386 8.61785 2.50534 8.57731 2.62699L8.21485 3.71429H8.51547C8.86177 3.4661 9.6169 2.86534 9.76475 2.47194C9.90839 2.08966 10.2037 1.06156 10.3573 0.517969C10.2675 0.552935 10.1936 0.602208 10.1107 0.657458C9.92954 0.77823 9.70402 0.928571 9.28552 0.928571C8.82121 0.928571 8.5895 0.619647 8.40332 0.371429C8.22538 0.134208 8.11392 0 7.89255 0C7.6712 0 7.55977 0.134208 7.38186 0.371429C7.19571 0.619676 6.96403 0.928571 6.49975 0.928571C6.03546 0.928571 5.80378 0.619647 5.6176 0.371429C5.4397 0.134208 5.32824 0 5.10689 0C4.88554 0 4.77411 0.134208 4.59623 0.371429C4.41006 0.619647 4.17838 0.928571 3.71412 0.928571C3.29562 0.928571 3.07016 0.77823 2.88897 0.657429C2.80615 0.602237 2.73224 0.552935 2.64258 0.517969C2.79611 1.06153 3.09148 2.08963 3.23512 2.47192C3.38294 2.86531 4.13807 3.4661 4.48437 3.71426H4.785V3.71429Z" />
        <path d="M4.51408 4.17859C4.43504 4.26686 4.2918 4.42925 4.11279 4.64287H8.88806C8.70899 4.42925 8.56576 4.26686 8.48665 4.17859H4.51408Z" />
        <path d="M6.49986 11.6071C7.90789 11.6071 9.05343 10.4616 9.05343 9.05357C9.05343 7.64554 7.90789 6.5 6.49986 6.5C5.09183 6.5 3.94629 7.64554 3.94629 9.05357C3.94629 10.4616 5.09183 11.6071 6.49986 11.6071ZM4.42587 8.2743C4.4543 8.19984 4.51901 8.14517 4.59719 8.12953L5.67094 7.9148L6.31415 7.05714C6.35799 6.99867 6.42679 6.96429 6.49986 6.96429C6.57293 6.96429 6.64173 6.99867 6.68557 7.05714L7.32878 7.91477L8.40253 8.1295C8.48071 8.14514 8.54542 8.19981 8.57385 8.27427C8.60229 8.34876 8.59057 8.43262 8.54272 8.4964L7.90856 9.34195L8.12036 10.4009C8.13658 10.482 8.10838 10.5657 8.04637 10.6204C8.00331 10.6584 7.94846 10.6785 7.89272 10.6785C7.86814 10.6785 7.84336 10.6746 7.81933 10.6666L6.49986 10.2269L5.18042 10.6667C5.10193 10.6928 5.01542 10.6753 4.95338 10.6204C4.89134 10.5657 4.86317 10.482 4.87939 10.4009L5.09119 9.34198L4.457 8.49643C4.40915 8.43265 4.3974 8.34879 4.42587 8.2743Z" />
        <path d="M1.63377 11.1804C1.57809 11.6416 1.72286 12.1054 2.03097 12.453C2.3392 12.8006 2.78245 13 3.24708 13H9.7525C10.2171 13 10.6604 12.8006 10.9686 12.4529C11.2767 12.1053 11.4215 11.6415 11.3658 11.1804C11.1536 9.423 10.9981 8.30398 10.9163 7.94434C10.7184 7.07488 9.90987 5.92214 9.26553 5.10712H3.73426C3.08983 5.92237 2.28105 7.07543 2.08332 7.94434C2.00146 8.30401 1.84601 9.423 1.63377 11.1804ZM6.49981 6.03572C8.16387 6.03572 9.51766 7.38952 9.51766 9.05357C9.51766 10.7176 8.16387 12.0714 6.49981 12.0714C4.83575 12.0714 3.48195 10.7176 3.48195 9.05357C3.48195 7.38952 4.83575 6.03572 6.49981 6.03572Z" />
        <path d="M4.06598 3.9827C3.97429 4.08629 3.85212 4.22732 3.7108 4.39678C3.34344 4.52504 2.88234 4.71162 2.42531 4.96669C1.12415 5.69214 0.464286 6.59866 0.464286 7.66071C0.464286 7.78897 0.360402 7.89285 0.232143 7.89285C0.103884 7.89285 0 7.78897 0 7.66071C0 6.43238 0.76375 5.35872 2.20855 4.55609C2.84665 4.20178 3.47895 3.97486 3.89129 3.84805C3.95484 3.89825 4.01375 3.94381 4.06598 3.9827Z" />
        <path d="M13.0001 7.66071C13.0001 7.78897 12.8962 7.89285 12.7679 7.89285C12.6397 7.89285 12.5358 7.78897 12.5358 7.66071C12.5358 6.59866 11.8759 5.69214 10.5748 4.96669C10.1177 4.71191 9.65663 4.52475 9.28897 4.39678C9.14794 4.22732 9.02578 4.08629 8.93408 3.9827C8.98631 3.94381 9.04522 3.89825 9.10877 3.84805C9.52111 3.97486 10.1534 4.20178 10.7915 4.55609C12.2363 5.35872 13.0001 6.43238 13.0001 7.66071Z" />
    </svg>
</template>
