<template>
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.816 5.91596L14.9656 4.76636L13.8344 3.63476L12.6046 4.86456C11.4811 4.06839 10.1701 3.5777 8.8 3.44056V1.80056H10.4V0.200562H5.6V1.80056H7.2V3.44056C5.82987 3.5777 4.51887 4.06839 3.3954 4.86456L2.1656 3.63476L1.0344 4.76636L2.184 5.91596C1.11092 7.05212 0.394117 8.4777 0.12211 10.0167C-0.149897 11.5556 0.0348075 13.1405 0.653413 14.5757C1.27202 16.0109 2.29743 17.2334 3.60302 18.0924C4.90861 18.9513 6.43719 19.409 8 19.409C9.56281 19.409 11.0914 18.9513 12.397 18.0924C13.7026 17.2334 14.728 16.0109 15.3466 14.5757C15.9652 13.1405 16.1499 11.5556 15.8779 10.0167C15.6059 8.4777 14.8891 7.05212 13.816 5.91596ZM8 17.8006C6.7342 17.8006 5.49683 17.4252 4.44435 16.722C3.39188 16.0187 2.57157 15.0192 2.08717 13.8497C1.60277 12.6803 1.47603 11.3935 1.72298 10.152C1.96992 8.9105 2.57946 7.77013 3.47452 6.87507C4.36957 5.98002 5.50994 5.37048 6.75142 5.12353C7.9929 4.87659 9.27973 5.00333 10.4492 5.48773C11.6186 5.97213 12.6182 6.79243 13.3214 7.84491C14.0246 8.89738 14.4 10.1348 14.4 11.4006C14.3981 13.0974 13.7232 14.7241 12.5234 15.9239C11.3236 17.1237 9.6968 17.7986 8 17.8006Z" fill="white"/>
        <path d="M13.816 5.91596L14.9656 4.76636L13.8344 3.63476L12.6046 4.86456C11.4811 4.06839 10.1701 3.5777 8.8 3.44056V1.80056H10.4V0.200562H5.6V1.80056H7.2V3.44056C5.82987 3.5777 4.51887 4.06839 3.3954 4.86456L2.1656 3.63476L1.0344 4.76636L2.184 5.91596C1.11092 7.05212 0.394117 8.4777 0.12211 10.0167C-0.149897 11.5556 0.0348075 13.1405 0.653413 14.5757C1.27202 16.0109 2.29743 17.2334 3.60302 18.0924C4.90861 18.9513 6.43719 19.409 8 19.409C9.56281 19.409 11.0914 18.9513 12.397 18.0924C13.7026 17.2334 14.728 16.0109 15.3466 14.5757C15.9652 13.1405 16.1499 11.5556 15.8779 10.0167C15.6059 8.4777 14.8891 7.05212 13.816 5.91596ZM8 17.8006C6.7342 17.8006 5.49683 17.4252 4.44435 16.722C3.39188 16.0187 2.57157 15.0192 2.08717 13.8497C1.60277 12.6803 1.47603 11.3935 1.72298 10.152C1.96992 8.9105 2.57946 7.77013 3.47452 6.87507C4.36957 5.98002 5.50994 5.37048 6.75142 5.12353C7.9929 4.87659 9.27973 5.00333 10.4492 5.48773C11.6186 5.97213 12.6182 6.79243 13.3214 7.84491C14.0246 8.89738 14.4 10.1348 14.4 11.4006C14.3981 13.0974 13.7232 14.7241 12.5234 15.9239C11.3236 17.1237 9.6968 17.7986 8 17.8006Z" fill="url(#icon-timer-gradient-1)"/>
        <path d="M8.00056 6.6006V11.4006H3.20056C3.20056 12.3499 3.48208 13.278 4.00951 14.0673C4.53694 14.8567 5.28659 15.4719 6.16368 15.8352C7.04076 16.1985 8.00588 16.2936 8.93699 16.1084C9.8681 15.9232 10.7234 15.466 11.3947 14.7947C12.066 14.1234 12.5231 13.2681 12.7083 12.337C12.8935 11.4059 12.7985 10.4408 12.4352 9.56372C12.0719 8.68663 11.4566 7.93698 10.6673 7.40955C9.87794 6.88212 8.94991 6.6006 8.00056 6.6006Z" fill="white"/>
        <path d="M8.00056 6.6006V11.4006H3.20056C3.20056 12.3499 3.48208 13.278 4.00951 14.0673C4.53694 14.8567 5.28659 15.4719 6.16368 15.8352C7.04076 16.1985 8.00588 16.2936 8.93699 16.1084C9.8681 15.9232 10.7234 15.466 11.3947 14.7947C12.066 14.1234 12.5231 13.2681 12.7083 12.337C12.8935 11.4059 12.7985 10.4408 12.4352 9.56372C12.0719 8.68663 11.4566 7.93698 10.6673 7.40955C9.87794 6.88212 8.94991 6.6006 8.00056 6.6006Z" fill="url(#icon-timer-gradient-2)"/>
        <defs>
            <linearGradient id="icon-timer-gradient-1" x1="16" y1="-2.64514" x2="-3.29971" y2="0.784217" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
            <linearGradient id="icon-timer-gradient-2" x1="12.8006" y1="5.17838" x2="1.37505" y2="7.61569" gradientUnits="userSpaceOnUse">
                <stop stop-color="#00ffc2"/>
                <stop offset="1" stop-color="#00aa6d"/>
            </linearGradient>
        </defs>
    </svg>
</template>
