<template>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.75 0H2.25C1.00736 0 0 1.00736 0 2.25V14.25C0 15.4926 1.00736 16.5 2.25 16.5H4.91251L4.50452 20.1675C4.45904 20.5792 4.75593 20.9498 5.16766 20.9953C5.38027 21.0188 5.59278 20.9503 5.75178 20.8073L10.5383 16.5H18.75C19.9926 16.5 21 15.4926 21 14.25V2.25C21 1.00736 19.9926 0 18.75 0Z" fill="#7D9892"/>
        <path d="M18.75 0H2.25C1.00736 0 0 1.00736 0 2.25V14.25C0 15.4926 1.00736 16.5 2.25 16.5H4.91251L4.50452 20.1675C4.45904 20.5792 4.75593 20.9498 5.16766 20.9953C5.38027 21.0188 5.59278 20.9503 5.75178 20.8073L10.5383 16.5H18.75C19.9926 16.5 21 15.4926 21 14.25V2.25C21 1.00736 19.9926 0 18.75 0Z" fill="url(#icon-chat-gradient-1)"/>
        <defs>
            <linearGradient id="icon-chat-gradient-1" x1="21" y1="-1.46999" x2="-3.05645" y2="5.27228" gradientUnits="userSpaceOnUse">
                <stop stop-color="#3b7eb7"/>
                <stop offset="1" stop-color="#145081"/>
            </linearGradient>
        </defs>
    </svg>
</template>
